/* ***************
 contact-form
******************/

.form-control:focus {
  box-shadow: none;
}

.contact-form .title {
  text-transform: capitalize;
  color: $color-dark;
  line-height: 24px;
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.col-form-label {
  font-size: 14px;
  color: $color-dark;
  padding-top: 0.625rem;
  text-transform: capitalize;
}
.contact-form .form-control {
  background-color: $color-white;
  border: 1px solid $border-color;
  font-size: 0.6875rem;
  color: $body-color;
  padding: 0.8rem 1.6rem;
  height: 38px;
  line-height: 1.25;
  @include border-radius(0px);
}

.contact-form textarea.form-control {
  min-height: 300px;
  @include max-screen(767) {
    min-height: 150px;
  }
}

h3.contact-page-title {
  font-family: $gilroyMedium;
  font-weight: fontweight(medium);
  font-size: 30px;
  line-height: 32px;
  margin-bottom: 25px;
  color: $color-dark;
}

@media only screen and (max-width: 575px) {
  h3.contact-page-title {
    font-size: 25px;
  }
}

.single-contact-block {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $border-color;
}

@media only screen and (max-width: 575px) {
  .single-contact-block {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

.single-contact-block:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.single-contact-block h4 {
  font-family: $gilroyMedium;
  font-weight: fontweight(medium);
  font-size: 20px;
  line-height: 27px;
  color: $color-dark;
  margin: 15px 0px;
}

@media only screen and (max-width: 575px) {
  .single-contact-block h4 {
    font-size: 20px;
  }
}

.single-contact-block h4 img {
  margin: 5px 20px 20px 0;
}

.single-contact-block p {
  font-size: 15px;
  line-height: 29px;
  margin-bottom: 0;
  color: $body-color;
}

span.required {
  color: $theme-color;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form label {
  font-size: 14px;
  color: $color-dark;
  padding: 0.8rem 0rem;
  text-transform: capitalize;
}

.contact-form input {
  line-height: 40px;
  width: 100%;
  height: 40px;
  padding-left: 20px;
  border: 1px solid $border-color;
  outline: 0;
  background-color: $color-white;
}

.contact-form textarea {
  line-height: 40px;
  width: 100%;
  height: 155px;
  padding-left: 20px;
  border: 1px solid $border-color;
  outline: 0;
  background-color: $color-white;
}

.contact-page-side-content {
  padding-right: 30px;
  height: 100%;
}

/* login page */

.log-in-form {
  border: 1px solid $border-color;
  font-size: 13px;
  color: $body-color;
  padding: 30px 15px 15px;
}

.log-in-form .form-control {
  background-color: $color-white;
  border: 1px solid $border-color;
  font-size: 14px;
  color: $body-color;
  padding: 0.8rem 1.6rem;
  height: 38px;
  line-height: 1.25;
  @include border-radius(0px);
}

.show-password {
  font-family: $gilroyBold;
  font-weight: fontweight(bold);
  color: $color-white;
  border: 0;
  text-transform: uppercase;
  font-size: 0.6875rem;
  padding: 0.67rem 1rem !important;
  @include border-radius(0px);
}

.login-form-links a {
  display: inline-block;
}

.for-get {
  font-size: 14px;
  padding-bottom: 15px;
}

.border-top {
  border-top: 1px solid $border-color !important;
}

.no-account {
  display: inline-block;
  font-size: 14px;
  margin-top: 15px;
}

/* ***************
 contact-form End
******************/
