/******************************** 
    slick arrow style
 ********************************/

.slick-arrow {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 50%;
  border: 1px solid $border-color;
  background: $color-white;
  color: $grey-soft;
  text-align: center;
  z-index: 1;
  font-size: 14px;
  line-height: 50px;
  @include transform(translateY(-50%));
  @include border-radius(50%);
}

// .main-slider {
//   .slick-prev {
//     left: 0px;
//   }
//   &:hover {
//     .slick-prev {
//       left: -40px;
//     }
//   }
//   .slick-next {
//     right: 0px;
//   }
//   &:hover {
//     .slick-next {
//       right: -40px;
//     }
//   }
// }

// slick-nav
.slick-nav {
  .slick-prev {
    left: -15px;
    @include min-screen(1500) {
      left: -40px;
    }
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .slick-prev {
      left: -15px;
      opacity: 1;
      visibility: visible;
      @include max-screen(1480) {
        left: 0px;
      }
    }
  }
  .slick-next {
    right: -15px;
    @include min-screen(1500) {
      right: -40px;
    }
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .slick-next {
      right: -15px;
      @include max-screen(1480) {
        right: 0px;
      }
      visibility: visible;
      opacity: 1;
    }
  }
}
// slick-nav-sync
.slick-nav-sync {
  .slick-prev {
    left: -15px;
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .slick-prev {
      left: 15px;
      opacity: 1;
      visibility: visible;
    }
  }
  .slick-next {
    right: -15px;
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .slick-next {
      right: 15px;

      visibility: visible;
      opacity: 1;
    }
  }
}
// slick-nav-brand
.slick-nav-brand {
  .slick-prev {
    left: 0px;
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .slick-prev {
      left: -40px;
      opacity: 1;
      visibility: visible;
      @include max-screen(1480) {
        left: -10px;
      }
    }
  }
  .slick-next {
    right: 0px;
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .slick-next {
      right: -40px;
      @include max-screen(1480) {
        right: -10px;
      }
      visibility: visible;
      opacity: 1;
    }
  }
}
// blog-init
.blog-init {
  .slick-prev {
    left: 0px;
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .slick-prev {
      left: -30px;
      opacity: 1;
      visibility: visible;
      @include max-screen(1480) {
        left: -15px;
      }
    }
  }
  .slick-next {
    right: 0px;
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .slick-next {
      right: -30px;
      @include max-screen(1480) {
        right: -15px;
      }
      visibility: visible;
      opacity: 1;
    }
  }
}

// theme1
.theme1 .slick-arrow {
  &:hover {
    color: $color-white;
    background: $theme-color;
    border-color: $theme-color;
  }
}
// theme2
.theme2 .slick-arrow {
  &:hover {
    color: $color-white;
    background: $theme-color2;
    border-color: $theme-color2;
  }
}
// theme3
.theme3 .slick-arrow {
  &:hover {
    color: $color-white;
    background: $theme-color3;
    border-color: $theme-color3;
  }
}

/* slick progress */

.slick-progress {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 5px;
  z-index: 1;
  span {
    width: 0;
    height: 100%;
    position: absolute;
    background-color: $progress-color;
    @include transition(0s linear);
    &.active {
      width: 100%;
    }
  }
}

/* slick dots style */
.dots-style {
  .slick-dots {
    position: absolute;
    left: 0;
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      line-height: 1;
      button {
        width: 14px;
        height: 14px;
        text-indent: 2000px;
        font-size: 0px;
        display: block;
        margin: 0 5px;
        @include transition(0.3s);
        @include border-radius(50%);
        &:hover {
          @include transform(scale3d(1, 1, 1));
        }
      }
    }
  }
}
// popular-slider
// theme2
.popular-slider-init {
  &.dots-style {
    .slick-dots {
      bottom: 0px;
      position: static;
      margin-top: 30px;
      li {
        button {
          width: 8px;
          height: 8px;
          @include transition(0.3s linear);
        }
      }
    }
  }
}

// theme1
.theme1 {
  .slick-dots {
    li {
      button {
        background: $color-white;
        &:hover {
          background: $theme-color;
        }
      }
      &.slick-active {
        button {
          background: $theme-color;
        }
      }
    }
  }
}
// theme2
.theme2 {
  .slick-dots {
    li {
      button {
        background: $themedot2;
        &:hover {
          background: $theme-color2;
        }
      }
      &.slick-active {
        button {
          background: $theme-color2;
        }
      }
    }
  }
}

// theme3
.theme3 {
  .slick-dots {
    li {
      button {
        background: $color-white;
        &:hover {
          background: $theme-color3;
        }
      }
      &.slick-active {
        button {
          background: $theme-color3;
        }
      }
    }
  }
}

// popular-slider
// theme1

.theme1 {
  .popular-slider-init {
    &.dots-style {
      .slick-dots {
        li {
          button {
            background: $color-dark;
            &:hover {
              background: $theme-color;
            }
          }
          &.slick-active {
            button {
              width: 28px;
              background: $theme-color;
              @include border-radius(8px);
            }
          }
        }
      }
    }
  }
}

// theme2

.theme2 {
  .popular-slider-init {
    &.dots-style {
      .slick-dots {
        li {
          button {
            background: $color-white;
            &:hover {
              background: $theme-color2;
            }
          }
          &.slick-active {
            button {
              width: 28px;
              background: $theme-color2;
              @include border-radius(8px);
            }
          }
        }
      }
    }
  }
}

// theme3

.theme3 {
  .popular-slider-init {
    &.dots-style {
      .slick-dots {
        li {
          button {
            background: $color-white;
            &:hover {
              background: $theme-color3;
            }
          }
          &.slick-active {
            button {
              width: 28px;
              background: $theme-color3;
              @include border-radius(8px);
            }
          }
        }
      }
    }
  }
}

/******************************** 
    slick arrow style ENd
 ********************************/
