/*------------------*
# About Us Page
*------------------*/

.about-content .title {
  font-size: 34px;
  font-family: $gilroyMedium;
  font-weight: fontweight(medium);
  color: $color-dark;
  text-transform: capitalize;
}

.about-info .title {
  font-size: 34px;
  font-family: $gilroyMedium;
  font-weight: fontweight(medium);
  color: $color-dark;
}

/* *******************
   about us page End
 **********************/
