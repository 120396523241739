/*------------------*
# Headings
*------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  font-style: normal;
  font-weight: fontweight(regular);
  text-transform: normal;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
h1 {
  font-size: 40px;
  font-weight: 500;
}
h2 {
  font-size: 35px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
ul {
  margin: 0px;
  padding: 0px;
}
li {
  list-style: none;
}
