@charset "UTF-8";
/*-----------------------------------------------------------------------------------

    Template Name: Junno – Multipurpose eCommerce HTML Template
    Template URL:
    Description: Junno - Multipurpose eCommerce Bootstrap5 HTML5 Template
    Author: Hastech
    Author URL: 
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

   [ 01. Theme default CSS ]
   [ 02. header CSS ]
   [ 02.1 header sticky CSS ]
   [ 02.2 search-form CSS ]
   [ 02.3 cart-links CSS ]
   [ 02.4 offcanvas-menu-toggler CSS ]
   [ 02.5 offcanvas CSS ]
   [ 02.6 offcanvas-cart CSS ]
   [ 02.7 all-ctry-menu CSS ]
   [ 03. main-slider CSS ]
   [ 03.1 banner CSS ]
   [ 03.2 bread-crumbs CSS ]
   [ 04. tabs-slider CSS ]
   [ 05. product-slider CSS ]
   [ 06. popular-slider CSS ]
   [ 07. featured-slider CSS ]
   [ 08. single-product-sync CSS ]
   [ 09. single-product-sync-nav CSS ]
   [ 09.1 single-product CSS ]
   [ 10. blog-slider CSS ]
   [ 11. brand-slider CSS ]
   [ 12. slick arrow dots CSS ]
   [ 13. product-tags CSS ]
   [ 14. counter CSS ]
   [ 15. check-box CSS ]
   [ 16. static-media CSS ]
   [ 17. media CSS ]
   [ 18. pagination CSS ]
   [ 19. modal CSS ]
   [ 20. Footer CSS ]
   [ 21. footer CSS ]
   [ 22. scroll-up CSS ]
   [ 23. pages ]
   [ 23.1 blogPages ]
   [ 23.2 shopPages ]
   [ 23.3 about-us ]
   [ 23.4 whishlist ]
   [ 23.5 contact-us ]
   [ 23.6 account ]
   [ 23.7 check-out ]
   [ 23.8 compare ]
   [ 23.9 map ]


-----------------------------------------------------------------------------------*/
/**************************** 
[ 01. Theme default CSS ]
*****************************/
/* local fonts */
@font-face {
  font-family: "Gilroy-Bold ☞";
  font-style: normal;
  font-weight: 700;
  src: local("Gilroy-Bold ☞"), url("../fonts/Gilroy-Bold.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Heavy ☞";
  font-style: normal;
  font-weight: 900;
  src: local("Gilroy-Heavy ☞"), url("../fonts/Gilroy-Heavy.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Light ☞";
  font-style: normal;
  font-weight: 300;
  src: local("Gilroy-Light ☞"), url("../fonts/Gilroy-Light.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Medium ☞";
  font-style: normal;
  font-weight: 500;
  src: local("Gilroy-Medium ☞"), url("../fonts/Gilroy-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Regular ☞";
  font-style: normal;
  font-weight: 400;
  src: local("Gilroy-Regular ☞"), url("../fonts/Gilroy-Regular.woff") format("woff");
}

/*---------------------------*
 #Template default CSS
*----------------------------*/
body {
  background: #ffffff;
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  font-size: 14px;
  color: #707070;
  line-height: 24px;
  visibility: visible;
  overflow-x: hidden;
}

@media screen and (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1440px;
  }
}

@media screen and (min-width: 576px) and (max-width: 1199px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gilroy-Medium ☞";
  line-height: 1;
  margin: 0;
}

p {
  margin: 0px;
}

a,
button {
  text-decoration: none;
  color: inherit;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
  outline: 0;
}

a,
button,
img,
input {
  transition: all 0.3s;
}

a:focus {
  text-decoration: none;
  outline: 0;
}

a:hover {
  text-decoration: none;
  color: #f33535;
}

button,
input[type="submit"] {
  cursor: pointer;
  outline: 0 !important;
}

img {
  max-width: 100%;
}

:focus {
  outline: 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

span {
  display: inline-block;
}

span.separator {
  color: #bfbfbf;
  margin: 0 8px;
  line-height: 1;
  font-size: 14px;
}

.zoom-in img {
  transition: all 0.3s linear;
}

.zoom-in:hover img {
  transform: scale(1.045) rotate(0.05deg);
}

.theme-border {
  border: 2px solid #f33535 !important;
}

.theme-border2 {
  border: 2px solid #ff7519 !important;
}

@media screen and (min-width: 1200px) {
  .position-xl-relative {
    position: relative;
  }
}

.custom-column .row > .col-xl-5 {
  max-width: 100%;
}

@media screen and (min-width: 1920px) {
  .custom-column .row > .col-xl-5 {
    width: 50%;
  }
}

@media screen and (max-width: 1919px) {
  .custom-column .row > .col-xl-5 {
    width: 60%;
  }
}

@media screen and (max-width: 1200px) {
  .custom-column .row > .col-xl-5 {
    width: 72%;
  }
}

@media screen and (max-width: 991px) {
  .custom-column .row > .col-xl-5 {
    width: 40%;
  }
}

@media screen and (max-width: 575px) {
  .custom-column .row > .col-xl-5 {
    width: 100%;
  }
}

.custom-column .row > .col-xl-4 {
  max-width: 100%;
}

@media screen and (min-width: 1920px) {
  .custom-column .row > .col-xl-4 {
    width: 36%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1919px) {
  .custom-column .row > .col-xl-4 {
    width: auto;
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .custom-column .row > .col-xl-4 {
    width: 100%;
    display: block;
  }
}

.custom-column .row > .col-xl-3 {
  max-width: 100%;
}

@media screen and (min-width: 1920px) {
  .custom-column .row > .col-xl-3 {
    width: 14%;
  }
}

@media screen and (max-width: 1919px) {
  .custom-column .row > .col-xl-3 {
    width: 40%;
  }
}

@media screen and (max-width: 1200px) {
  .custom-column .row > .col-xl-3 {
    width: 28%;
  }
}

@media screen and (max-width: 991px) {
  .custom-column .row > .col-xl-3 {
    width: 60%;
  }
}

@media screen and (max-width: 575px) {
  .custom-column .row > .col-xl-3 {
    width: 100%;
  }
}

@media screen and (min-width: 1920px) {
  .none-in-xxl {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .none-in-xxl {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .position-lg-static {
    position: static !important;
  }
}

.zIndex-3 {
  z-index: 3;
}

hr.hr {
  background-color: #ebebeb;
  opacity: 1;
}

span.required {
  color: #f33535;
}

.theme1 .hover-color:hover {
  color: #f33535 !important;
}

.w-md-50 {
  width: 100%;
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .w-md-50 {
    max-width: 50%;
  }
}

/*------------------*
# Headings
*------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  font-style: normal;
  font-weight: 400;
  text-transform: normal;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 40px;
  font-weight: 500;
}

h2 {
  font-size: 35px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

/*-----------------------
# selection colors
-------------------------*/
*::-moz-selection {
  background: #10a341;
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background: #10a341;
  color: #ffffff;
  text-shadow: none;
}

/*-----------------------
# Input Placeholder
-------------------------*/
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999 !important;
  opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #999 !important;
  opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #999 !important;
  opacity: 1;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #999 !important;
  opacity: 1;
}

input::placeholder,
textarea::placeholder {
  color: #999 !important;
  opacity: 1;
}

/*-------------------------- 
input active placeholder 
--------------------------*/
input:active::-webkit-input-placeholder,
textarea:active::-webkit-input-placeholder {
  color: #999 !important;
  opacity: 1;
}

input:active::-moz-placeholder,
textarea:active::-moz-placeholder {
  color: #999 !important;
  opacity: 1;
}

input:active:-ms-input-placeholder,
textarea:active:-ms-input-placeholder {
  color: #999 !important;
  opacity: 1;
}

input:active::-ms-input-placeholder,
textarea:active::-ms-input-placeholder {
  color: #999 !important;
  opacity: 1;
}

input:active::placeholder,
textarea:active::placeholder {
  color: #999 !important;
  opacity: 1;
}

/*-------------------------
 background images 
 -------------------------*/
.bg-img {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.bg-img1 {
  background-image: url("../img/slider/slide1.jpg");
}

.bg-img2 {
  background-image: url("../img/slider/slide2.jpg");
}

.bg-img3 {
  background-image: url("../img/slider/slide3.jpg");
}

.bg-img4 {
  background-image: url("../img/slider/slide4.jpg");
}

.bg-img5 {
  background-image: url("../img/slider/slide5.jpg");
}

.bg-img6 {
  background-image: url("../img/slider/slide6.jpg");
}

.bg-img7 {
  background-image: url("../img/slider/slide7.jpg");
}

.bg-img8 {
  background-image: url("../img/slider/slide8.jpg");
}

@media (max-width: 991px) {
  .bg-img {
    background-position: 0% bottom;
  }
  .bg-img.bg-img3 {
    background-position: 75% bottom;
  }
  .bg-img.bg-img4 {
    background-position: 25% bottom;
  }
  .wraped .bg-img {
    background-position: 40% bottom;
  }
}

/*------------------------
  background colors 
  ------------------------*/
.theme-bg {
  background-color: #f33535 !important;
}

.theme-bg2 {
  background-color: #ff7519 !important;
}

.theme-bg3 {
  background-color: #10a341 !important;
}

.bg-light {
  background-color: #efefef !important;
}

.bg-lighten {
  background-color: #f5f5f5 !important;
}

.bg-lighten2 {
  background-color: #f6f6f6;
}

.bg-dark {
  background-color: #1d1d1d !important;
}

/*-------------------
  text-color 
 -------------------*/
.theme-color {
  color: #f33535;
}

.theme-color2 {
  color: #ff7519;
}

.theme-color3 {
  color: #10a341;
}

.text-warning {
  color: #fdd835 !important;
}

.text-dark {
  color: #1d1d1d !important;
}

.text-muted {
  color: #707070 !important;
}

/*-----------------------
# Margin & Padding
-------------------------*/
.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.py-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.px-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.px-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}

/*------------------
 # Margin Top
------------------*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

/*------------------
 # Margin Bottom
------------------*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

/*------------------
 # Margin Left
------------------*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

/*------------------
 # Margin Right
------------------*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

/*------------------
 # Padding Top
------------------*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

/*------------------
 # Padding Bottom
------------------*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

/*---------------
 # Padding Left
---------------*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

/*---------------
 # Padding Right
---------------*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

@media screen and (min-width: 1920px) {
  .px-xl-90 {
    padding-left: 90px;
    padding-right: 90px;
  }
}

@media screen and (max-width: 991px) {
  .mr-pr-0 {
    margin-right: 0px;
    padding-right: 0px;
  }
}

/****************************
 button style 
 ****************************/
.btn {
  font-family: "Gilroy-Bold ☞";
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  border-radius: 5px;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

.theme--btn-default {
  color: #ffffff;
  background: #f33535;
}

.theme--btn-default:hover {
  color: #ffffff;
  background: #1d1d1d;
}

.theme--btn1 {
  color: #1d1d1d;
  background: #ffffff;
}

.theme--btn1:hover {
  color: #ffffff;
  background: #f33535;
}

.theme--btn2 {
  color: #ffffff;
  background: #ff7519;
}

.theme--btn2:hover {
  background: #1d1d1d;
  color: #ffffff;
}

.theme--btn3 {
  color: #1d1d1d;
  background: #ffffff;
}

.theme--btn3:hover {
  color: #ffffff;
  background: #10a341;
}

.theme-btn--light {
  background-color: #f6f6f6;
  color: #1d1d1d;
}

.theme-btn--light1:hover {
  color: #ffffff;
  background: #f33535;
}

.theme-btn--light2:hover {
  color: #ffffff;
  background: #ff7519;
}

.theme-btn--light3:hover {
  color: #ffffff;
  background: #10a341;
}

.theme--btn-ddefault {
  background: #f6f6f6;
  color: #1d1d1d;
}

.theme--btn-ddefault:hover {
  background: #10a341;
  color: #ffffff;
}

.theme-btn--dark {
  color: #ffffff;
  background: #1d1d1d;
}

.theme-btn--dark1 {
  color: #ffffff;
  background: #1d1d1d;
}

.theme-btn--dark1:hover {
  color: #ffffff;
  background: #f33535;
}

.theme-btn--dark2 {
  color: #ffffff;
  background: #1d1d1d;
}

.theme-btn--dark2:hover {
  color: #ffffff;
  background: #ff7519;
}

.theme-btn--dark3 {
  color: #ffffff;
  background: #1d1d1d;
}

.theme-btn--dark3-sm {
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
}

.theme-btn--dark3:hover {
  color: #ffffff;
  background: #10a341;
}

.btn--sm {
  padding: 5px 20px;
}

.btn--md {
  padding: 10px 25px;
}

.btn--lg {
  padding: 15px 30px;
}

.btn--xl {
  padding: 18px 35px;
}

/* border radius in rounded class*/
.rounded {
  border-radius: 3rem !important;
}

.btn-rounded {
  border-radius: 0 5px 5px 0;
}

.rounded-5 {
  border-radius: 5px;
}

/* custom button styles */
.search-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 65px;
  height: 100%;
  color: #ffffff;
  font-size: 21px;
  text-align: center;
  transition: all 0.3s;
}

.search-btn.position-left {
  right: auto;
  left: 0;
}

.search-btn.theme-bg:hover {
  background-color: #1d1d1d !important;
  color: #ffffff;
}

.search-btn.bg-dark:hover {
  background-color: #292929 !important;
  color: #ffffff;
}

.search-btn.bg-white {
  width: 45px;
  font-size: 25px;
  background-color: transparent !important;
  padding: 0;
  color: #1d1d1d !important;
}

.search-btn.bg-white:hover {
  color: #1d1d1d !important;
}

.nletter-btn {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  padding: 0 20px;
  line-height: 47px;
  height: 47px;
  border-radius: 0 30px 30px 0;
}

.theme1 .nletter-btn {
  background: #f33535;
  border: 0px solid #f33535;
}

.theme1 .nletter-btn:hover {
  background-color: #1d1d1d;
  color: #ffffff;
  border-color: #1d1d1d;
}

.theme2 .nletter-btn {
  background: #ff7519;
  border: 0px solid #ff7519;
}

.theme2 .nletter-btn:hover {
  background-color: #1d1d1d;
  color: #ffffff;
  border-color: #1d1d1d;
}

.theme3 .nletter-btn {
  background: #10a341;
  border: 0px solid #10a341;
}

.theme3 .nletter-btn:hover {
  background-color: #1d1d1d;
  color: #ffffff;
  border-color: #1d1d1d;
}

.banner-btn {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shop-now-btn {
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  padding: 7px;
  color: #ffffff;
  background: #f33535;
  display: block;
  width: 100%;
  transition: all 0.3s linear;
  border-radius: 0px;
}

.shop-now-btn:hover {
  color: #ffffff;
  background: #1d1d1d;
}

.blog-search-btn {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  padding: 0 20px;
  line-height: 47px;
  height: 100%;
  border-radius: 0 30px 30px 0;
}

.theme1 .blog-search-btn {
  background: #f33535;
  border: 1px solid #f33535;
}

.theme1 .blog-search-btn:hover {
  background-color: #1d1d1d;
  color: #ffffff;
  border-color: #1d1d1d;
}

/****************************
 button style  END
 ****************************/
/*******************************
 section-title style 
 *******************************/
.section-title .title {
  font-size: 30px;
  font-weight: 500;
  font-family: "Gilroy-Medium ☞";
}

@media screen and (max-width: 480px) {
  .section-title .title {
    font-size: 30px;
  }
}

.section-title .text {
  color: #9d9d9d;
  line-height: 18px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Gilroy-Medium ☞";
}

@media screen and (max-width: 767px) {
  .section-title .text {
    line-height: 25px;
  }
}

/*******************************
 section-title style  End
 *******************************/
/*******************************
    badge style 
 *******************************/
.badge {
  position: absolute;
  text-align: center;
  padding: 5px;
  line-height: 1;
  font-size: 12px;
  z-index: 2;
  font-weight: 700;
  text-transform: capitalize;
}

.theme1 .badge-danger {
  color: #ffffff;
  background-color: #f33535 !important;
}

.badge-success {
  color: #ffffff;
  background-color: #10a341 !important;
}

.theme2 .badge-danger {
  color: #ffffff;
  background-color: #ff7519 !important;
}

.theme3 .badge-danger {
  color: #ffffff;
  background-color: #10a341 !important;
}

.theme3 .badge-success {
  color: #ffffff;
  background-color: #f33535 !important;
}

.cbdg1 {
  width: 20px;
  height: 20px;
  bottom: 2px;
  left: 17px;
  border-radius: 50%;
}

.theme1 .cbdg1 {
  color: #ffffff;
  background-color: #f33535 !important;
}

.theme2 .cbdg1 {
  color: #1d1d1d;
  background-color: #ffffff !important;
}

.theme3 .cbdg1 {
  color: #ffffff;
  background-color: #10a341 !important;
}

.cb6 {
  display: inline-block;
  text-align: center;
  font-size: 18px;
  padding: 10px;
}

.top-left, .top-right {
  top: 10px;
  left: 10px;
}

.top-right {
  left: auto;
  right: 10px;
}

/*******************************
    badge style  END
 *******************************/
/**************************** 
[ 02. header CSS ]
*****************************/
/**************************** 
top-nav-style1 start
*****************************/
/*------------------*
# Top Nave
*------------------*/
.static-info p {
  font-size: 14px;
  line-height: 14px;
}

@media screen and (max-width: 767px) {
  .static-info p {
    line-height: 25px;
  }
}

.navbar-top li {
  position: relative;
}

.navbar-top li img {
  margin-right: 5px;
}

.navbar-top li a {
  font-weight: 400;
  text-transform: capitalize;
  padding: 0 15px;
  line-height: 14px;
  font-size: 14px;
}

.navbar-top li.english .topnav-submenu {
  right: 0 !important;
  left: auto !important;
}

.theme1 .navbar-top ul li a {
  color: #ffffff;
}

.theme1 .navbar-top ul li a:hover {
  color: #f33535;
}

.theme2 .navbar-top ul li a {
  color: #ffffff;
}

.theme2 .navbar-top ul li a:hover {
  color: #ff7519;
}

/* topnav-submenu */
.topnav-submenu {
  top: 50px !important;
  right: 15px !important;
  left: auto !important;
  min-width: 130px;
  overflow: hidden;
  padding: 0 15px;
  background: #ffffff;
  z-index: 3;
  border-radius: 0;
  transform: translate3d(0px, 0px, 0px) !important;
  margin: 0;
  border: 0;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: ease-in 0.3s;
  -webkit-transition: ease-in 0.3s;
  -moz-transition: ease-in 0.3s;
  -ms-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  box-shadow: 0 0 3.76px 0.24px rgba(0, 0, 0, 0.15);
}

.topnav-submenu.show {
  top: 40px !important;
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 767px) {
  .topnav-submenu.show {
    top: 30px !important;
  }
}

@media screen and (max-width: 991px) {
  .topnav-submenu {
    right: auto !important;
    left: 0 !important;
  }
}

.topnav-submenu li a {
  display: block;
  padding: 10px;
  line-height: 25px;
  font-size: 13px;
  border-bottom: 1px solid #ebebeb;
}

.topnav-submenu li:last-child a {
  border-bottom: 0px;
}

.theme1 .topnav-submenu li a {
  color: #1d1d1d !important;
}

.theme1 .topnav-submenu li a:hover {
  color: #f33535 !important;
}

.theme1 .topnav-submenu li.active a {
  color: #f33535 !important;
}

.theme2 .topnav-submenu li a {
  color: #1d1d1d !important;
}

.theme2 .topnav-submenu li a:hover {
  color: #ff7519 !important;
}

.theme2 .topnav-submenu li.active a {
  color: #ff7519 !important;
}

.theme3 .topnav-submenu li a {
  color: #1d1d1d !important;
}

.theme3 .topnav-submenu li a:hover {
  color: #10a341 !important;
}

.theme3 .topnav-submenu li.active a {
  color: #10a341 !important;
}

.theme2 .offcanvas-top-nav ul li a {
  color: #1d1d1d !important;
}

.theme2 .offcanvas-top-nav ul li a:hover {
  color: #ff7519 !important;
}

.theme2 .offcanvas-top-nav ul li.active a {
  color: #ff7519 !important;
}

.theme3 .offcanvas-top-nav ul li a {
  color: #1d1d1d !important;
}

.theme3 .offcanvas-top-nav ul li a:hover {
  color: #10a341 !important;
}

.theme3 .offcanvas-top-nav ul li.active a {
  color: #10a341 !important;
}

/*------------------*
# Top Nave ENd
*------------------*/
/**************************** 
# top-nav-style1 end
*****************************/
/**************************** 
# main-menu start
*****************************/
/*------------------*
# Main-menu
*------------------*/
.main-menu li {
  margin: 0 1px;
  position: relative;
}

.main-menu li a {
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  display: block;
  padding: 15px 20px;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  text-transform: uppercase;
}

.main-menu li a i {
  font-size: 16px;
  position: relative;
  top: 1px;
}

.theme1 li a {
  color: #1d1d1d;
}

.theme1 li:hover > a {
  color: #f33535;
}

.theme1 li.active > a {
  color: #f33535;
}

.theme2 li a {
  color: #1d1d1d;
  line-height: 1.2;
}

@media screen and (min-width: 992px) {
  .theme2 li a {
    color: #ffffff;
  }
}

.theme2 li:hover > a {
  color: #ff7519;
}

.theme2 li.active > a {
  color: #ff7519;
}

.theme3 li a {
  color: #1d1d1d;
  line-height: 1.2;
}

@media screen and (min-width: 992px) {
  .theme3 li a {
    color: #ffffff;
  }
}

.theme3 li:hover > a {
  color: #10a341;
}

.theme3 li.active > a {
  color: #10a341;
}

/*------------------*
# Main-menu END
*------------------*/
/**************************** 
# main-menu end
*****************************/
/**************************** 
# mega-menu start
*****************************/
/*------------------*
# mega-menu
*------------------*/
.mega-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-width: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
  background: #ffffff;
  padding: 30px 15px;
  box-shadow: 0 0 3.76px 0.24px rgba(0, 0, 0, 0.15);
  margin: 0 15px;
  transform-origin: 0 0;
  transform-style: preserve-3d;
  transform: rotateX(-75deg);
}

.main-menu li:hover > .mega-menu {
  opacity: 1;
  visibility: visible;
  transition: transform 0.3s, opacity 0.3s;
  transform: rotateX(0deg);
}

.mega-menu > li {
  margin: 0;
}

.mega-menu li ul li {
  margin: 0;
}

.mega-menu li > ul li:last-child a {
  padding-bottom: 0;
}

.mega-menu li > ul li > a:hover {
  padding-left: 15px;
}

.mega-menu li ul li.mega-menu-title {
  margin-bottom: 20px;
}

.mega-menu li ul li.mega-menu-title a {
  color: #1d1d1d;
  line-height: 1;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding: 0 !important;
}

.mega-menu li a {
  text-align: left;
  padding: 0 0 5px;
  font-family: "Gilroy-Regular ☞";
  color: #707070;
  text-transform: capitalize;
  line-height: 28px;
  font-weight: 400;
  font-size: 14px;
}

.mega-menu-custom-with {
  max-width: 1110px;
  min-width: 1110px;
  left: 23%;
}

@media screen and (max-width: 1200px) {
  .mega-menu-custom-with {
    min-width: 95%;
    left: 0%;
    right: 0%;
  }
}

.theme1 .mega-menu li ul li.mega-menu-title a:hover {
  color: #f33535;
}

.theme2 .mega-menu li ul li.mega-menu-title a:hover {
  color: #ff7519;
}

.theme3 .mega-menu li ul li.mega-menu-title a:hover {
  color: #10a341;
}

.blog-ctry-menu li a {
  position: relative;
  font-weight: 400;
  color: #1d1d1d;
  display: block;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 35px;
}

.blog-ctry-menu li a::before {
  content: "\f2c7";
  position: absolute;
  display: inline-block;
  font-family: "Ionicons";
  right: 0;
}

.blog-ctry-menu li.active a::before {
  content: "\f462";
}

.blog-ctry-menu li ul.category-sub-menu {
  display: none;
}

.blog-ctry-menu li ul.category-sub-menu li a {
  font-size: 13px;
  line-height: 35px;
  padding: 0 10px;
  display: block;
  color: #707070;
}

.blog-ctry-menu li ul.category-sub-menu li a::before {
  content: "";
}

/*------------------*
# mega-menu END
*------------------*/
/**************************** 
# mega-menu end
*****************************/
/**************************** 
# sub-menu start
*****************************/
/*------------------*
# sub-menu
*------------------*/
.sub-menu {
  position: absolute;
  padding: 0;
  min-width: 240px;
  z-index: 4;
  box-shadow: 0 0 3.76px 0.24px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  transform-origin: 0 0;
  transform-style: preserve-3d;
  transform: rotateX(-75deg);
}

.main-menu li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transition: transform 0.3s, opacity 0.3s;
  transform: rotateX(0deg);
}

.sub-menu li {
  padding: 0 20px;
  border-bottom: 1px solid #eee;
  margin: 0;
}

.sub-menu li a {
  font-weight: 400;
  font-family: "Gilroy-Regular ☞";
  text-align: left;
  text-transform: capitalize;
  padding: 0;
  color: #707070;
  font-size: 14px;
  line-height: 40px;
}

.sub-menu li a i {
  margin-left: 10px;
}

.sub-menu li:hover > a {
  padding-left: 10px;
}

.sub-menu .sub-menu {
  opacity: 0;
  visibility: hidden;
  left: 100%;
  top: 0;
  transform-origin: 0 0;
  transform-style: preserve-3d;
  transform: rotateX(-75deg);
}

/*------------------*
# sub-menu END
*------------------*/
/**************************** 
# sub-menu end
*****************************/
/**************************** 
# verticle-menu start
*****************************/
/*------------------*
# vertical-menu
*------------------*/
.vertical-menu {
  position: relative;
  z-index: 2;
}

.vertical-menu .menu-btn {
  max-width: 270px;
  min-width: 220px;
  background: #10a341;
  color: #ffffff;
  font-size: 14px;
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  text-transform: uppercase;
  line-height: 26px;
  padding: 10px 20px;
  border-radius: 5px;
}

.vertical-menu .menu-btn span.ion-android-menu {
  display: inline-block;
  font-size: 24px;
  margin-right: 10px;
  vertical-align: -3px;
}

.vertical-menu .display-none {
  display: none;
}

.vertical-menu .vmenu-content {
  width: 100%;
  max-width: 360px;
  min-width: 270px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 270px;
  box-shadow: 0 0 3.76px 0.24px rgba(0, 0, 0, 0.15);
}

.vertical-menu .vmenu-content.display-block {
  min-width: inherit;
}

.vertical-menu .vmenu-content li {
  display: block;
  position: relative;
}

.vertical-menu .vmenu-content li a {
  position: relative;
  display: block;
  overflow: hidden;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
  background: #ffffff;
  color: #707070;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.3s linear;
}

.vertical-menu .vmenu-content li a i {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.vertical-menu .vmenu-content li a strong {
  font-family: "Gilroy-Bold ☞";
  color: #1d1d1d;
  text-transform: uppercase;
}

.vertical-menu .vmenu-content li a strong:hover {
  color: #10a341;
}

.vertical-menu .vmenu-content li .verticale-mega-menu,
.vertical-menu .vmenu-content li .verticale-sub-menu {
  position: absolute;
  z-index: 99;
  text-align: left;
  top: 0;
  left: 100%;
  background: #ffffff;
  padding: 20px 10px;
  width: 590px;
  display: flex;
  opacity: 0;
  visibility: hidden;
  border: 1px solid #ebebeb;
  box-shadow: 0 0 3.76px 0.24px rgba(0, 0, 0, 0.15);
  transform-origin: center center;
  transform: translateY(20px);
}

.vertical-menu .vmenu-content li .verticale-mega-menu li {
  width: 50%;
}

.vertical-menu .vmenu-content li .verticale-mega-menu li a {
  margin: 5px 0px 5px 0px;
}

.vertical-menu .vmenu-content li .verticale-mega-menu li a:hover {
  background: #ffffff;
}

.vertical-menu .vmenu-content li .verticale-mega-menu li ul li {
  width: 100%;
}

.vertical-menu .vmenu-content li .verticale-mega-menu li ul li a {
  padding: 2px 20px 2px 20px;
}

.vertical-menu .vmenu-content li .verticale-mega-menu li ul li a:hover {
  padding-left: 30px;
  background: #ffffff;
}

.vertical-menu .vmenu-content li .verticale-sub-menu {
  padding: 0;
  width: 240px;
  border: 1px solid #ebebeb;
  background: #ffffff;
}

.vertical-menu .vmenu-content li .verticale-sub-menu li {
  width: 100%;
  padding: 0;
  line-height: 20px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
  display: block;
}

.vertical-menu .vmenu-content li .verticale-sub-menu li:last-child {
  border: 0;
}

.vertical-menu .vmenu-content li .verticale-sub-menu li a {
  padding: 10px 20px;
}

.vertical-menu .vmenu-content li .verticale-sub-menu li:hover > a {
  padding: 10px 20px 10px 25px;
}

.vertical-menu .vmenu-content li:hover .verticale-mega-menu,
.vertical-menu .vmenu-content li:hover .verticale-sub-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: all 0.3s;
}

.vertical-menu .vmenu-content li:hover .verticale-mega-menu a,
.vertical-menu .vmenu-content li:hover .verticale-sub-menu a {
  background: #ffffff;
}

.vertical-menu .vmenu-content li.expand a {
  padding-left: 45px;
  font-weight: 700;
}

.vertical-menu .vmenu-content li.expand a i {
  right: auto;
  left: 20px;
}

.theme3 .vertical-menu li:hover > a {
  color: #10a341;
  background: #f9f9f9;
}

/*------------------*
# vertical-menu END
*------------------*/
/**************************** 
# verticle-menu end
*****************************/
/**************************** 
[ 02.1 header sticky CSS ]
*****************************/
/*******************************
 sticky header
 *******************************/
@media screen and (min-width: 992px) {
  #sticky.is-isticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: all 0.3s;
    animation: ease-in-out 0.5s fadeInDown;
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
  }
}

.theme1.is-isticky {
  background: rgba(255, 255, 255, 0.8);
}

.theme1.is-isticky:hover {
  background: #ffffff;
}

.theme2.is-isticky {
  background: rgba(29, 29, 29, 0.8) !important;
}

.theme2.is-isticky:hover {
  background: #1d1d1d !important;
}

.theme3.is-isticky {
  background: rgba(29, 29, 29, 0.8) !important;
}

.theme3.is-isticky:hover {
  background: #1d1d1d !important;
}

@media screen and (min-width: 992px) {
  #sticky.custom-sticky.is-isticky {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

@media screen and (max-width: 991px) {
  #sticky.custom-sticky.is-isticky {
    position: static !important;
  }
}

/*******************************
 sticky header End
 *******************************/
/**************************** 
[ 02.2 search-form CSS ]
*****************************/
/* ***************
 search-form
******************/
.search-form .form-control {
  padding: 10px 70px 10px 20px;
  width: 100%;
  height: 46px;
  font-size: 14px;
  background: #ffffff;
  color: #1d1d1d;
  border: 2px solid transparent;
  border-radius: 5px;
}

.search-form .form-control:focus {
  box-shadow: none;
}

.search-form .form-control.theme1-border {
  border-color: #eee;
}

.search-form .form-control.theme2-border {
  border-color: #253237;
}

.search-form .form-control.theme3 {
  padding: 10px 20px 10px 54px;
}

@media screen and (max-width: 991px) {
  .search-form .form-control.theme3 {
    border-color: #eee;
  }
}

.search-body {
  position: absolute;
  width: 600px;
  top: 40px;
  right: 255px;
  display: none;
  z-index: 2;
}

@media screen and (max-width: 1500px) {
  .search-body {
    right: 220px;
  }
}

@media screen and (max-width: 1100px) {
  .search-body {
    right: 205px;
  }
}

.width-calc-from-left {
  width: calc(100% - 220px);
}

@media screen and (max-width: 991px) {
  .width-calc-from-left {
    width: calc(100%);
    padding-left: 0px;
  }
}

/* ***************
 search-form End
******************/
/**************************** 
[ 02.3 cart-links CSS ]
*****************************/
/*************************************
   cart-block-links CSS 
*************************************/
.cart-block-links li {
  margin-right: 30px;
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .cart-block-links li {
    margin-right: 20px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-block-links li {
    margin-right: 15px;
  }
}

.cart-block-links li a {
  font-size: 16px;
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  text-align: center;
  line-height: 26px;
}

.cart-block-links li a i {
  font-size: 28px;
}

.cart-block-links .cart-total {
  top: -5px;
  margin-left: 10px;
}

.cart-block-links.theme1 a {
  color: #1d1d1d;
}

.cart-block-links.theme1 a:hover {
  color: #f33535;
}

.cart-block-links.theme2 a {
  color: #ffffff !important;
}

.cart-block-links.theme2 a:hover {
  color: #ffffff;
}

.cart-block-links.theme3 a {
  color: #1d1d1d;
}

.cart-block-links.theme3 a:hover {
  color: #10a341;
}

/*************************************
   cart-block-links CSS  END
*************************************/
/*********************************** 
[ 02.4 offcanvas-menu-toggler CSS ]
************************************/
/******************************** 
  offcanvas toggler btn
 ********************************/
.mobile-menu-toggle svg {
  width: 50px;
  height: 50px;
}

.mobile-menu-toggle svg path {
  transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  fill: none;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 30px;
}

.mobile-menu-toggle svg path#top, .mobile-menu-toggle svg path#bottom {
  stroke-dasharray: 240px 950px;
}

.mobile-menu-toggle svg path#middle {
  stroke-dasharray: 240px 240px;
}

.mobile-menu-toggle .close svg path#top, .mobile-menu-toggle .close svg path#bottom {
  stroke-dasharray: -650px;
  stroke-dashoffset: -650px;
}

.mobile-menu-toggle .close svg path#middle {
  stroke-dasharray: 1px 220px;
  stroke-dashoffset: -115px;
}

.mobile-menu-toggle.theme1 svg path {
  stroke: #1d1d1d;
}

.mobile-menu-toggle.theme2 svg path {
  stroke: #ffffff;
}

.mobile-menu-toggle.theme3 svg path {
  stroke: #1d1d1d;
}

/******************************** 
  offcanvas toggler btn END
 ********************************/
/**************************** 
[ 02.5 offcanvas CSS ]
*****************************/
/******************************** 
 offcanvas menu style
 ********************************/
body.offcanvas-open {
  overflow-y: hidden;
  padding-right: 17px;
}

@media screen and (max-width: 991px) {
  body.offcanvas-open {
    padding-right: 0px;
  }
}

.offcanvas-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.offcanvas {
  font-size: 14px;
  font-weight: 400;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: auto;
  display: block;
  overflow: hidden;
  width: 400px;
  height: 100vh;
  transform: translateX(100%);
  background-color: #ffffff;
  box-shadow: none;
  transition: all 0.5s ease-in-out;
}

.offcanvas.offcanvas-open {
  transform: translateX(0);
}

.offcanvas.offcanvas-mobile-menu {
  right: auto;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-mobile-menu.offcanvas-open {
  transform: translateX(0);
}

@media screen and (max-width: 480px) {
  .offcanvas {
    width: 300px;
  }
}

.offcanvas .inner {
  padding: 20px;
}

@media screen and (max-width: 991px) {
  .offcanvas .inner {
    overflow-y: scroll;
    height: 660px;
  }
}

@media screen and (max-width: 767px) {
  .offcanvas .inner {
    height: calc(100% - 30px);
  }
}

@media screen and (max-width: 480px) {
  .offcanvas .inner {
    padding: 10px;
  }
}

.offcanvas .title {
  color: #1d1d1d;
  font-weight: 700;
}

.offcanvas .offcanvas-close {
  position: relative;
  width: 20px;
  height: 20px;
  text-indent: -9999px;
  border: 0;
  background-color: transparent;
}

.offcanvas .offcanvas-close::after {
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 20px;
  height: 2px;
  content: "";
  transition: all 0.5s ease-in-out;
  transform: rotate(-45deg);
  background-color: #1d1d1d;
}

.offcanvas .offcanvas-close:hover:after {
  transform: rotate(0deg);
}

.offcanvas .offcanvas-close::before {
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 20px;
  height: 2px;
  content: "";
  transition: all 0.5s ease-in-out;
  transform: rotate(45deg);
  background-color: #1d1d1d;
}

.offcanvas .offcanvas-close:hover:before {
  transform: rotate(180deg);
}

.offcanvas .offcanvas-menu ul li {
  position: relative;
  display: block;
  line-height: 28px;
}

.offcanvas .offcanvas-menu ul li a {
  display: block;
  position: relative;
  padding: 8px 20px;
  text-transform: capitalize;
  border-top: 1px solid #eee;
  font-size: 14px;
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  line-height: 30px;
}

.offcanvas .offcanvas-menu ul li .offcanvas-submenu {
  position: static;
  top: auto;
  display: none;
  visibility: visible;
  width: 100%;
  min-width: auto;
  transition: none;
  opacity: 1;
  box-shadow: none;
}

.offcanvas .offcanvas-menu ul li .offcanvas-submenu li {
  line-height: inherit;
  position: relative;
}

.offcanvas .offcanvas-menu ul li .offcanvas-submenu li a {
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  padding-left: 40px;
}

.offcanvas .offcanvas-menu ul li .offcanvas-submenu li ul a {
  padding-left: 60px;
}

.offcanvas .offcanvas-menu ul li .menu-expand {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 24px;
  height: 44px;
  cursor: pointer;
  background-color: transparent;
}

.offcanvas .offcanvas-menu ul li .menu-expand::before, .offcanvas .offcanvas-menu ul li .menu-expand::after {
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 2px;
  content: "";
  transition: all 0.5s ease-in-out;
  background-color: #1d1d1d;
}

.offcanvas .offcanvas-menu ul li .menu-expand::after {
  transform: rotate(90deg);
}

.offcanvas .offcanvas-menu ul li.active > .menu-expand::after {
  transform: rotate(0) scale(0.75);
}

.offcanvas .offcanvas-social li {
  display: inline-block;
  margin: 0 10px 10px 0;
  line-height: 40px;
  padding: 0;
}

.offcanvas .offcanvas-social li a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid transparent;
  z-index: 1;
}

.theme1 .offcanvas-menu li:hover > .menu-expand:after {
  background: #f33535;
}

.theme1 .offcanvas-menu li:hover > .menu-expand:before {
  background: #f33535;
}

.theme2 .offcanvas-menu li > a:hover {
  color: #ff7519;
}

.theme2 .offcanvas-menu li:hover > .menu-expand:after {
  background: #ff7519;
}

.theme2 .offcanvas-menu li:hover > .menu-expand:before {
  background: #ff7519;
}

.theme3 .offcanvas-menu li > a:hover {
  color: #10a341;
}

.theme3 .offcanvas-menu li:hover > .menu-expand:after {
  background: #10a341;
}

.theme3 .offcanvas-menu li:hover > .menu-expand:before {
  background: #10a341;
}

.theme1 .offcanvas-social a {
  color: #ffffff;
  background: #f33535;
}

.theme1 .offcanvas-social a:hover {
  background: transparent;
  color: #f33535;
  border-color: #f33535;
}

.theme2 .offcanvas-social a {
  color: #ffffff;
  background: #ff7519;
}

.theme2 .offcanvas-social a:hover {
  background: transparent;
  color: #ff7519;
  border-color: #ff7519;
}

.theme3 .offcanvas-social a {
  color: #ffffff;
  background: #10a341;
}

.theme3 .offcanvas-social a:hover {
  background: transparent;
  color: #10a341;
  border-color: #10a341;
}

/******************************** 
 offcanvas menu style ENd
 ********************************/
/**************************** 
[ 02.6 offcanvas-cart CSS ]
*****************************/
/******************************** 
 offcanvas cart style
 ********************************/
.offcanvas .inner .head {
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.offcanvas .inner .head .offcanvas-close {
  position: relative;
  width: 20px;
  height: 20px;
  text-indent: -9999px;
  border: 0;
  background-color: transparent;
}

.offcanvas .inner .sub-total {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #eee;
  margin: 30px 0;
  border-bottom: 1px solid #eee;
}

.offcanvas .inner .sub-total strong {
  font-family: "Gilroy-Medium ☞";
  color: #1d1d1d;
}

.offcanvas .inner .sub-total .amount {
  font-weight: 700;
  font-family: "Gilroy-Bold ☞";
}

.offcanvas .inner .minicart-message {
  margin-top: 15px;
  color: #1d1d1d;
  margin-bottom: 15px;
}

.minicart-product-list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.minicart-product-list li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ebebeb;
}

.minicart-product-list li .image {
  flex: 1 0 0px;
}

.minicart-product-list li .image img {
  max-width: 100%;
  border: 1px solid #ebebeb;
}

@media screen and (max-width: 575px) {
  .minicart-product-list li .image {
    flex: 1 0 50px;
  }
}

.minicart-product-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.minicart-product-list li .content {
  position: relative;
  flex: 1 0 calc(100% - 150px);
  padding-left: 15px;
}

.minicart-product-list li .content .title {
  color: #1d1d1d;
  line-height: 18px;
  margin-bottom: 10px;
  font-size: 15px;
  text-transform: capitalize;
  font-family: "Gilroy-Bold ☞";
}

.minicart-product-list li .content .quantity-price {
  font-size: 14px;
  display: block;
  margin-top: 10px;
}

.minicart-product-list li .content .quantity-price .amount {
  color: #1d1d1d;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
}

.minicart-product-list li .content .remove {
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 3px;
  color: #1d1d1d;
  font-size: 18px;
}

@media screen and (max-width: 575px) {
  .minicart-product-list li .content {
    flex: 1 0 calc(100% - 75px);
  }
}

.theme1 .inner .sub-total .amount {
  color: #f33535;
}

.theme2 .inner .sub-total .amount {
  color: #ff7519;
}

.theme3 .inner .sub-total .amount {
  color: #10a341;
}

.theme1 .minicart-product-list li .content .title:hover {
  color: #f33535;
}

.theme1 .minicart-product-list li .content .remove:hover {
  color: #f33535;
}

.theme2 .minicart-product-list li .content .title:hover {
  color: #ff7519;
}

.theme2 .minicart-product-list li .content .remove:hover {
  color: #ff7519;
}

.theme3 .minicart-product-list li .content .title:hover {
  color: #10a341;
}

.theme3 .minicart-product-list li .content .remove:hover {
  color: #10a341;
}

/******************************** 
 offcanvas cart style ENd
 ********************************/
/**************************** 
[ 02.7 all-ctry-menu CSS ]
*****************************/
/*******************************
    hero-side-category style 
 *******************************/
.hero-side-category {
  width: 100%;
  position: relative;
}

.hero-side-category .category-toggle {
  padding: 10px 20px;
  background-color: #ffffff;
  color: #1d1d1d;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 24px;
  width: 100%;
  text-align: left;
  border: 2px solid #eee;
  border-radius: 5px;
  position: relative;
  font-family: "Gilroy-Bold ☞";
}

.hero-side-category .category-toggle i {
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  font-size: 18px;
  transform: translate(0, 2px);
}

.hero-side-category .category-menu {
  background-color: #ffffff;
  width: 100%;
  left: 0;
  top: 110%;
  border: 1px solid #ebebeb;
  position: absolute;
  display: none;
  z-index: 2;
  height: 500px;
  overflow-x: auto;
  border-radius: 5px;
}

.hero-side-category .category-menu li a {
  display: block;
  padding: 10px 25px;
  line-height: 30px;
  font-size: 14px;
  color: #253237;
  font-weight: 400;
  position: relative;
  border-bottom: 1px solid #ebebeb;
}

.hero-side-category .category-menu li a i {
  position: absolute;
  right: 30px;
}

.hero-side-category .category-menu li .category-mega-menu {
  display: none;
}

.hero-side-category .category-menu li .category-mega-menu a {
  padding: 10px 25px 10px 35px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #253237;
  position: relative;
  margin: 0;
  text-transform: inherit;
}

.hero-side-category .category-menu li #more-btn {
  font-weight: 700;
  padding-left: 45px;
}

.hero-side-category .category-menu li #more-btn i {
  right: auto;
  left: 30px;
}

.theme1 .category-menu li:hover > a {
  color: #f33535;
}

.theme2 .category-menu li:hover > a {
  color: #ff7519;
}

.theme3 .category-menu li:hover > a {
  color: #10a341;
}

/***********************************
    hero-side-category style  END
 ***********************************/
/*------------------*
# Main Content start
*------------------*/
/**************************** 
[ 03. main-slider CSS ]
*****************************/
/******************************** 
    Main Slider
 ********************************/
.slick-slider {
  background: transparent;
}

.slider-height {
  height: 605px;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .slider-height {
    height: 500px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height {
    height: 450px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height {
    height: 400px;
  }
}

@media screen and (max-width: 767px) {
  .slider-height {
    height: 300px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height {
    height: 350px;
  }
}

.slider-height2 {
  height: 714px;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .slider-height2 {
    height: 600px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height2 {
    height: 480px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height2 {
    height: 400px;
  }
}

@media screen and (max-width: 767px) {
  .slider-height2 {
    height: 300px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height2 {
    height: 350px;
  }
}

.slider-content-width {
  max-width: 52%;
}

@media screen and (min-width: 768px) and (max-width: 1500px) {
  .slider-content-width {
    max-width: 52%;
  }
}

@media screen and (max-width: 576px) {
  .slider-content-width {
    max-width: 100%;
  }
}

.slider-content .title {
  font-weight: 400;
  font-size: 65px;
  line-height: 1;
  opacity: 0;
  font-family: "Gilroy-Regular ☞";
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider-content .title {
    font-size: 50px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content .title {
    font-size: 45px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content .title {
    font-size: 35px;
  }
}

@media screen and (max-width: 767px) {
  .slider-content .title {
    font-size: 25px;
  }
}

.slider-content .sub-title {
  font-weight: 700;
  font-size: 65px;
  line-height: 1;
  opacity: 0;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider-content .sub-title {
    font-size: 50px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content .sub-title {
    font-size: 45px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content .sub-title {
    font-size: 35px;
  }
}

@media screen and (max-width: 767px) {
  .slider-content .sub-title {
    font-size: 25px;
  }
}

.slider-content .text {
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  opacity: 0;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content .text {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .slider-content .text {
    font-size: 15px;
  }
}

@media screen and (max-width: 767px) {
  .slider-content .text.mb-25 {
    margin-bottom: 15px;
  }
}

.slider-content .btn {
  opacity: 0;
}

@media screen and (max-width: 767px) {
  .slider-content .btn.mt-45 {
    margin-top: 30px;
  }
}

/******************************** 
# main slider End
********************************/
/**************************** 
[ 03.1 banner CSS ]
*****************************/
/****************************
#Common Banner
****************************/
.banner-thumb img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .banner-thumb img {
    width: 100%;
  }
}

/* hover-animation */
.hover-animation a::before {
  position: absolute;
  top: 0;
  left: 50%;
  right: 51%;
  bottom: 0;
  content: "";
  background: rgba(255, 255, 255, 0.2);
  opacity: 1;
}

.hover-animation a::after {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 50%;
  content: "";
  background: rgba(255, 255, 255, 0.2);
  opacity: 1;
}

.hover-animation:hover a::before {
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 0.9s linear;
}

.hover-animation:hover a::after {
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: all 0.9s linear;
}

/******************************** 
#common-banner End
********************************/
/**************************** 
[ 03.2 bread-crumbs CSS ]
*****************************/
/****************************
breadcrumb-section
 ****************************/
@media screen and (max-width: 767px) {
  .breadcrumb-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .breadcrumb-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.breadcrumb-item {
  text-transform: capitalize;
  line-height: 1;
  padding: 0px !important;
}

@media screen and (max-width: 450px) {
  .breadcrumb-item {
    margin: 5px 0px;
  }
}

.breadcrumb-item a {
  display: inline-block;
  position: relative;
  color: #707070 !important;
  line-height: 1;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\f3d3";
  color: #707070 !important;
  margin: 0 10px;
  content: "\f3d3";
  font-family: "Ionicons";
  padding: 0px !important;
  position: relative;
  top: 2px;
  font-size: 14px;
}

.theme1 .breadcrumb-item a:hover {
  color: #f33535 !important;
}

.theme1 .breadcrumb-item.active {
  color: #f33535;
}

.theme3 .breadcrumb-item a:hover {
  color: #10a341 !important;
}

.theme3 .breadcrumb-item.active {
  color: #10a341;
}

/****************************
breadcrumb-section END
 ****************************/
/**************************** 
[ 04. tabs-slider CSS ]
*****************************/
/******************************** 
    product tab menu style
 ********************************/
.product-tab-menu .nav-item {
  margin: 0px 2px;
}

@media screen and (max-width: 560px) {
  .product-tab-menu .nav-item {
    margin: 0px 2px 5px;
  }
}

.product-tab-menu .nav-item .nav-link {
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  line-height: 1;
  font-size: 14px;
  text-transform: uppercase;
  padding: 8px 18px;
  color: #666;
  border: 2px solid transparent;
  border-radius: 6px;
}

.theme1 .nav-link.active, .theme1 .nav-link:hover {
  color: #f33535;
  background: transparent;
  border-color: #f33535;
}

.theme2 .nav-link.active, .theme2 .nav-link:hover {
  color: #ff7519;
  background: transparent;
  border-color: #ff7519;
}

.theme3 .nav-link.active, .theme3 .nav-link:hover {
  color: #10a341;
  background: transparent;
  border-color: #10a341;
}

/******************************** 
    product tab menu style END
 ********************************/
/******************************** 
    product links style
 ********************************/
.product-links {
  position: absolute;
  z-index: 3;
  width: 100%;
  bottom: 15px;
}

.product-links li {
  margin: 0 3px;
}

.product-links li a {
  display: block;
  background: #f6f6f6;
  color: #1d1d1d;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  transform: scale3d(0, 0, 0);
  border-radius: 50%;
}

.product-card .card-body:hover .product-links li a {
  transform: scale3d(1, 1, 1);
}

.product-card .card-body:hover .product-links li:nth-child(2) a {
  transition: all 0.6s;
}

.product-card .card-body:hover .product-links li:nth-child(3) a {
  transition: all 0.9s;
}

.theme1 .product-links li:hover a {
  background: #f33535;
  color: #ffffff;
}

.theme2 .product-links li:hover a {
  background: #ff7519;
  color: #ffffff;
}

.theme3 .product-links li:hover a {
  background: #10a341;
  color: #ffffff;
}

/******************************** 
    product links style END
 ********************************/
/******************************** 
   product-card style 
 ********************************/
.tab-content .tab-pane.active .product-list {
  animation: zoomIn 0.5s ease;
  animation-duration: 1s;
}

.product-thumbnail img {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

img.second-img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.product-card {
  border: 0;
}

.product-card:hover {
  box-shadow: 0 0 11.83px 1.17px rgba(0, 0, 0, 0.06);
}

.product-card:hover img.second-img {
  opacity: 1;
  z-index: 1;
}

.product-card.no-shadow {
  box-shadow: none;
}

/* product desc style */
.product-desc {
  padding: 20px 10px 15px 10px;
}

.product-desc .title {
  line-height: 1.4;
  margin-bottom: 10px;
  color: #707070;
  font-size: 15px;
  text-transform: capitalize;
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
}

.theme1 .product-desc .title:hover a {
  color: #f33535;
}

.theme2 .product-desc .title:hover a {
  color: #ff7519;
}

.theme3 .product-desc .title:hover a {
  color: #10a341;
}

.star-rating span {
  font-weight: 400;
  line-height: 1;
  margin: 0 1px 0 0;
  font-size: 18px;
  color: #fdd835;
}

.star-rating span .de-selected {
  color: #eee;
}

.product-price {
  color: #1d1d1d;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  font-family: "Gilroy-Bold ☞";
}

.product-price .del {
  color: #9b9b9b;
  font-weight: 400;
  margin-right: 2px;
  line-height: 20px;
  font-size: 15px;
}

.theme1 .product-price .onsale {
  color: #f33535;
}

.theme2 .product-price .onsale {
  color: #ff7519;
}

.theme3 .product-price .onsale {
  color: #10a341;
}

.pro-btn {
  background: #f6f6f6;
  color: #1d1d1d;
  width: 40px;
  height: 40px;
  text-align: center;
  font-weight: 400;
  line-height: 40px;
  font-size: 16px;
  border-radius: 50%;
}

.pro-btn-right {
  position: absolute;
  right: 20px;
}

.theme1 .pro-btn:hover {
  background: #f33535;
  color: #ffffff;
}

.theme2 .pro-btn:hover {
  background: #ff7519;
  color: #ffffff;
}

.theme3 .pro-btn:hover {
  background: #10a341;
  color: #ffffff;
}

/******************************** 
   product-card style END
 ********************************/
/**************************** 
[ 05. product-slider CSS ]
*****************************/
/******************************** 
    product Slider
 ********************************/
.product-slider-init .slick-list {
  padding: 15px 0;
  margin: -15px;
}

.product-slider-init .slick-list .slick-slide {
  padding: 0 15px;
}

.product-slider2-init .slick-list {
  padding: 15px 0;
  margin: -15px;
}

.product-slider2-init .slick-list .slick-slide {
  padding: 0 15px;
}

/******************************** 
    product Slider End
 ********************************/
/**************************** 
[ 06. popular-slider CSS ]
*****************************/
/******************************** 
    popular Slider
 ********************************/
.popular-section {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.popular-section.popular-bg1 {
  background-image: url("../img/popular/popular-bg.jpg");
}

.popular-slider-init .slick-list {
  margin: 0 -15px;
}

.popular-slider-init .slick-list .slick-slide {
  padding: 0 15px;
}

.popular-card {
  text-align: center;
  background: #ffffff;
  border: 0;
}

.popular-card .card-body {
  padding: 15px;
  border-radius: 5px;
}

.popular-card .card-body .popular-title {
  padding: 25px 0 10px 0;
}

.popular-card .card-body .popular-title a {
  color: #1d1d1d;
  font-weight: 700;
  font-family: "Gilroy-Bold ☞";
  text-transform: capitalize;
  font-size: 18px;
  margin-bottom: 15px;
}

.popular-card-bg {
  position: relative;
}

.popular-card-bg .card-body {
  position: relative;
  z-index: 1;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 30px;
}

.popular-card-bg .card-body:hover .shop-now-btn {
  transform: translateY(30px);
}

.popular-card-bg::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(63% + 1px);
  content: "";
  z-index: 1;
  background: #f7f7f7;
}

.theme1 .popular-card {
  text-align: center;
}

.theme1 .popular-card .card-body .popular-title a:hover {
  color: #f33535;
}

.theme2 .popular-card {
  text-align: center;
}

.theme2 .popular-card .card-body .popular-title a:hover {
  color: #ff7519;
}

.theme3 .popular-card {
  text-align: center;
}

.theme3 .popular-card .card-body .popular-title a:hover {
  color: #10a341;
}

/******************************** 
    popular Slider ENd
 ********************************/
/**************************** 
[ 07. featured-slider CSS ]
*****************************/
/******************************** 
# featured section
********************************/
.featured-init .product-thumbnail img {
  max-width: 120px;
}

.featured-init .product-desc {
  padding: 15px 0px 0px;
}

.featured-init2 .product-thumbnail img {
  max-width: 120px;
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .featured-init2 .product-thumbnail img {
    max-width: 100px;
  }
}

/******************************** 
# featured section End
********************************/
/********************************* 
[ 08. single-product-sync CSS ]
**********************************/
/******************************** 
    product-ctry-init
 ********************************/
.product-ctry-init .product-desc .title {
  font-size: 18px;
  line-height: 20px;
}

@media screen and (max-width: 576px) {
  .product-ctry-init .product-desc .title {
    font-size: 15px;
  }
}

.product-ctry-init .product-price .del {
  font-size: 19px;
}

.product-ctry-init .product-price .onsale {
  font-size: 24px;
}

.availability p {
  font-size: 14px;
  text-transform: capitalize;
  color: #777;
}

.availability span.in-stock {
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  color: #10a341;
}

.hot-deal .title {
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  font-size: 30px;
}

/* clockdiv css */
.clockdiv {
  line-height: 1;
  margin-top: 20px;
}

.clockdiv .title {
  font-size: 14px;
  color: #707070;
  line-height: 18px;
  max-width: 100px;
  margin-right: 15px;
}

@media screen and (max-width: 767px) {
  .clockdiv .title {
    max-width: 100%;
    margin: 0 auto 15px;
    text-align: center;
  }
}

.clockdiv span {
  display: inline-block;
  padding: 0 10px;
  text-align: center;
  color: #1d1d1d;
  position: relative;
  font-weight: 700;
  font-size: 22px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .clockdiv span {
    font-size: 18px;
  }
}

.clockdiv span::after {
  position: absolute;
  top: 0;
  left: -4px;
  content: ":";
}

.clockdiv span:first-child::after {
  display: none;
}

.clockdiv span p {
  font-size: 12px;
  margin: 5px 0 0;
  color: #1d1d1d;
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
}

/******************************** 
    product-ctry-init ENd
 ********************************/
/************************************ 
[ 09. single-product-sync-nav CSS ]
*************************************/
/******************************** 
    countdown-sync-nav Slider
 ********************************/
.countdown-sync-nav {
  max-width: 80%;
  margin: auto;
}

.theme1 .product-sync-nav {
  max-width: 85%;
  margin: 0 auto;
}

.theme1 .product-sync-nav .slick-slide {
  padding: 0 5px;
}

.theme1 .product-sync-nav .slick-slide .product-thumb {
  border: 1px solid transparent;
}

.theme1 .product-sync-nav .slick-slide .product-thumb:hover {
  border-color: #f33535;
}

.theme1 .product-sync-nav .slick-slide.slick-current .product-thumb {
  border-color: #f33535;
}

.theme2 .product-sync-nav {
  max-width: 85%;
  margin: 0 auto;
}

.theme2 .product-sync-nav .slick-slide {
  padding: 0 5px;
}

.theme2 .product-sync-nav .slick-slide .product-thumb {
  border: 1px solid transparent;
}

.theme2 .product-sync-nav .slick-slide .product-thumb:hover {
  border-color: #ff7519;
}

.theme2 .product-sync-nav .slick-slide.slick-current .product-thumb {
  border-color: #ff7519;
}

.theme3 .product-sync-nav {
  max-width: 85%;
  margin: 0 auto;
}

.theme3 .product-sync-nav .slick-slide {
  padding: 0 5px;
}

.theme3 .product-sync-nav .slick-slide .product-thumb {
  border: 1px solid transparent;
}

.theme3 .product-sync-nav .slick-slide .product-thumb:hover {
  border-color: #10a341;
}

.theme3 .product-sync-nav .slick-slide.slick-current .product-thumb {
  border-color: #10a341;
}

.theme3 .product-sync-nav.single-product .slick-slide .product-thumb {
  max-width: 105px;
}

.has-opacity .slick-slide img {
  opacity: 0.3;
}

.has-opacity .slick-slide.slick-current.slick-active img {
  opacity: 1;
}

/******************************** 
    countdown-sync-nav Slider End
 ********************************/
/************************************ 
[ 10. blog-slider CSS ]
*************************************/
/*******************************
 blog-init
 *******************************/
.blog-init .slick-list {
  margin: 0 -15px;
}

.blog-init .slick-list .slick-slide {
  padding: 0 15px;
}

.blog-thumb img {
  width: 100%;
}

.blog-post-content .title {
  font-size: 18px;
  line-height: 1;
  color: #1d1d1d;
  font-weight: 700;
  font-family: "Gilroy-Bold ☞";
}

.blog-post-content .sub-title {
  text-transform: capitalize;
  font-size: 14px;
  margin-bottom: 15px;
  color: #707070;
}

.blog-post-content .text {
  margin-bottom: 15px;
}

.blog-post-content .read-more {
  font-size: 14px;
  text-transform: capitalize;
  display: inline-block;
  color: #f33535;
}

.blog-post-content .read-more:hover {
  text-decoration: underline;
}

.blog-post-content .separator {
  margin: 0 5px;
}

.blog-link {
  font-size: 12px;
  line-height: 24px;
  color: #f33535;
}

.blog-link:hover {
  text-decoration: underline;
}

.blog-links li {
  margin-bottom: 5px;
}

.blog-links li a {
  color: #666;
  line-height: 24px;
}

.blog-links li:hover a {
  color: #f33535;
}

/*******************************
 blog-init End
 *******************************/
/************************************ 
[ 11. brand-slider CSS ]
*************************************/
/******************************** 
     brand section 
  ********************************/
.brand-init.border-top {
  border-color: #ebebeb !important;
}

.brand-init .slick-list {
  margin: 0 -15px;
}

.brand-init .slick-list .slick-slide {
  padding: 0 15px;
}

.single-brand a img {
  display: block;
  margin: 0 auto;
}

.single-brand a:hover img {
  transform: scale(1.045) rotate(0.05deg);
}

/******************************** 
     brand section End
  ********************************/
/************************************ 
[ 12. slick arrow dots CSS ]
*************************************/
/******************************** 
    slick arrow style
 ********************************/
.slick-arrow {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 50%;
  border: 1px solid #ebebeb;
  background: #ffffff;
  color: #9d9d9d;
  text-align: center;
  z-index: 1;
  font-size: 14px;
  line-height: 50px;
  transform: translateY(-50%);
  border-radius: 50%;
}

.slick-nav .slick-prev {
  left: -15px;
  opacity: 0;
  visibility: hidden;
}

@media screen and (min-width: 1500px) {
  .slick-nav .slick-prev {
    left: -40px;
  }
}

.slick-nav:hover .slick-prev {
  left: -15px;
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 1480px) {
  .slick-nav:hover .slick-prev {
    left: 0px;
  }
}

.slick-nav .slick-next {
  right: -15px;
  opacity: 0;
  visibility: hidden;
}

@media screen and (min-width: 1500px) {
  .slick-nav .slick-next {
    right: -40px;
  }
}

.slick-nav:hover .slick-next {
  right: -15px;
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 1480px) {
  .slick-nav:hover .slick-next {
    right: 0px;
  }
}

.slick-nav-sync .slick-prev {
  left: -15px;
  opacity: 0;
  visibility: hidden;
}

.slick-nav-sync:hover .slick-prev {
  left: 15px;
  opacity: 1;
  visibility: visible;
}

.slick-nav-sync .slick-next {
  right: -15px;
  opacity: 0;
  visibility: hidden;
}

.slick-nav-sync:hover .slick-next {
  right: 15px;
  visibility: visible;
  opacity: 1;
}

.slick-nav-brand .slick-prev {
  left: 0px;
  opacity: 0;
  visibility: hidden;
}

.slick-nav-brand:hover .slick-prev {
  left: -40px;
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 1480px) {
  .slick-nav-brand:hover .slick-prev {
    left: -10px;
  }
}

.slick-nav-brand .slick-next {
  right: 0px;
  opacity: 0;
  visibility: hidden;
}

.slick-nav-brand:hover .slick-next {
  right: -40px;
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 1480px) {
  .slick-nav-brand:hover .slick-next {
    right: -10px;
  }
}

.blog-init .slick-prev {
  left: 0px;
  opacity: 0;
  visibility: hidden;
}

.blog-init:hover .slick-prev {
  left: -30px;
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 1480px) {
  .blog-init:hover .slick-prev {
    left: -15px;
  }
}

.blog-init .slick-next {
  right: 0px;
  opacity: 0;
  visibility: hidden;
}

.blog-init:hover .slick-next {
  right: -30px;
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 1480px) {
  .blog-init:hover .slick-next {
    right: -15px;
  }
}

.theme1 .slick-arrow:hover {
  color: #ffffff;
  background: #f33535;
  border-color: #f33535;
}

.theme2 .slick-arrow:hover {
  color: #ffffff;
  background: #ff7519;
  border-color: #ff7519;
}

.theme3 .slick-arrow:hover {
  color: #ffffff;
  background: #10a341;
  border-color: #10a341;
}

/* slick progress */
.slick-progress {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 5px;
  z-index: 1;
}

.slick-progress span {
  width: 0;
  height: 100%;
  position: absolute;
  background-color: rgba(49, 56, 72, 0.298);
  transition: all 0s linear;
}

.slick-progress span.active {
  width: 100%;
}

/* slick dots style */
.dots-style .slick-dots {
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dots-style .slick-dots li {
  line-height: 1;
}

.dots-style .slick-dots li button {
  width: 14px;
  height: 14px;
  text-indent: 2000px;
  font-size: 0px;
  display: block;
  margin: 0 5px;
  transition: all 0.3s;
  border-radius: 50%;
}

.dots-style .slick-dots li button:hover {
  transform: scale3d(1, 1, 1);
}

.popular-slider-init.dots-style .slick-dots {
  bottom: 0px;
  position: static;
  margin-top: 30px;
}

.popular-slider-init.dots-style .slick-dots li button {
  width: 8px;
  height: 8px;
  transition: all 0.3s linear;
}

.theme1 .slick-dots li button {
  background: #ffffff;
}

.theme1 .slick-dots li button:hover {
  background: #f33535;
}

.theme1 .slick-dots li.slick-active button {
  background: #f33535;
}

.theme2 .slick-dots li button {
  background: #d6d6d6;
}

.theme2 .slick-dots li button:hover {
  background: #ff7519;
}

.theme2 .slick-dots li.slick-active button {
  background: #ff7519;
}

.theme3 .slick-dots li button {
  background: #ffffff;
}

.theme3 .slick-dots li button:hover {
  background: #10a341;
}

.theme3 .slick-dots li.slick-active button {
  background: #10a341;
}

.theme1 .popular-slider-init.dots-style .slick-dots li button {
  background: #1d1d1d;
}

.theme1 .popular-slider-init.dots-style .slick-dots li button:hover {
  background: #f33535;
}

.theme1 .popular-slider-init.dots-style .slick-dots li.slick-active button {
  width: 28px;
  background: #f33535;
  border-radius: 8px;
}

.theme2 .popular-slider-init.dots-style .slick-dots li button {
  background: #ffffff;
}

.theme2 .popular-slider-init.dots-style .slick-dots li button:hover {
  background: #ff7519;
}

.theme2 .popular-slider-init.dots-style .slick-dots li.slick-active button {
  width: 28px;
  background: #ff7519;
  border-radius: 8px;
}

.theme3 .popular-slider-init.dots-style .slick-dots li button {
  background: #ffffff;
}

.theme3 .popular-slider-init.dots-style .slick-dots li button:hover {
  background: #10a341;
}

.theme3 .popular-slider-init.dots-style .slick-dots li.slick-active button {
  width: 28px;
  background: #10a341;
  border-radius: 8px;
}

/******************************** 
    slick arrow style ENd
 ********************************/
/************************************ 
[ 13. product-tags CSS ]
*************************************/
/******************************** 
   product-tags style
 ********************************/
.product-size .title {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #1d1d1d;
  line-height: 24px;
  font-family: "Gilroy-Bold ☞";
}

.product-size select {
  background-color: #ffffff;
  padding: 0 30px 0 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  color: #1d1d1d;
  border: 1px solid #ebebeb;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=) no-repeat scroll right 0.5rem center/1.25rem 1.25rem;
}

.product-size select option {
  font-weight: 400;
}

.select-arrow-down {
  position: absolute;
  left: calc(100% - 85px);
}

/* product-widget  */
.product-widget .title {
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  color: #1d1d1d;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  position: relative;
  display: block;
  border-bottom: 1px solid #ebebeb;
  text-transform: capitalize;
}

.product-widget .title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 60px;
  height: 2px;
  background: #f33535;
}

.product-tag li a {
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  text-transform: capitalize;
  display: block;
  padding: 10px 25px;
  font-size: 14px;
  line-height: 20px;
  color: #1d1d1d;
  border: 1px solid #ebebeb;
  background: #ffffff;
  margin: 4px;
  margin-left: 4px;
  border-radius: 30px;
}

.product-tag li a:hover {
  color: #ffffff;
  background: #f33535;
  border-color: #f33535;
}

/******************************** 
   product-tags style END
 ********************************/
/************************************ 
[ 14. counter CSS ]
*************************************/
/* *******************
 counter style
**********************/
.product-count.style input {
  color: #1d1d1d;
  background-color: #ffffff;
  height: 54px;
  padding: 10px 30px 10px 10px;
  width: 80px;
  border: 1px solid #ebebeb;
  display: block;
  text-align: center;
  -moz-appearance: textfield;
}

.product-count.style .count-btn {
  background-color: transparent;
  border: 0px;
  border-left: 1px solid #ebebeb;
  display: block;
  width: 20px;
  height: 27px;
  padding: 0px;
  text-align: center;
  color: #1d1d1d;
  font-size: 10px;
  transform: translateX(-100%);
  user-select: none;
}

.product-count.style .increment {
  border-bottom: 1px solid #ebebeb;
}

/* *******************
 counter style End
**********************/
/************************************ 
[ 15. check-box CSS ]
*************************************/
/*************************************
    check-box style CSS 
*************************************/
.filter-check-box {
  margin-bottom: 10px;
}

.check-box-inner {
  padding-top: 20px;
}

.check-box-inner .title {
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  color: #1d1d1d;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  display: block;
  border-bottom: 1px solid #ebebeb;
  text-transform: capitalize;
}

.check-box-inner .title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 60px;
  height: 2px;
  background: #f33535;
}

.check-box-inner .sub-title {
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  color: #1d1d1d;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 24px;
  text-transform: capitalize;
}

.filter-check-box input[type="checkbox"] {
  display: none;
}

.filter-check-box input[type="checkbox"] + label {
  position: relative;
  padding-top: 3px;
  padding-left: 30px;
  cursor: pointer;
  text-transform: capitalize;
  user-select: none;
  color: #1d1d1d;
  font-size: 14px;
  line-height: 22px;
}

.filter-check-box input[type="checkbox"] + label:hover {
  color: #f33535;
}

.filter-check-box input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 5px;
  opacity: 1;
}

.filter-check-box input[type="checkbox"] + label:hover:before {
  border-color: #f33535;
}

.filter-check-box.color-grey input[type="checkbox"] + label:before {
  background-color: #7e7e7e;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.filter-check-box.color-grey input[type="checkbox"]:checked + label:before {
  background-color: transparent;
}

.filter-check-box.color-white input[type="checkbox"] + label:before {
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.filter-check-box.color-black input[type="checkbox"] + label:before {
  background-color: #666;
  border: 1px solid #666;
}

.filter-check-box.color-camel input[type="checkbox"] + label:before {
  background-color: #c19a6b;
  border: 1px solid #c19a6b;
}

.filter-check-box.color-camel input[type="checkbox"]:checked + label:before {
  background-color: transparent;
}

.filter-check-box input[type="checkbox"]:checked + label:before {
  width: 10px;
  top: -1px;
  left: 5px;
  border-color: #f33535;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}

.theme1 .filter-check-box input[type="checkbox"]:checked + label:before {
  border-color: #f33535;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}

.theme2 .filter-check-box input[type="checkbox"]:checked + label:before {
  border-color: #ff7519;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}

.filter-check-box.color-black input[type="checkbox"]:checked + label:before {
  background: 0 0;
}

/* radio check box style */
.filter-check-box input[type="radio"]:checked,
.filter-check-box input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.filter-check-box input[type="radio"]:checked + label,
.filter-check-box input[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

.filter-check-box input[type="radio"]:checked + label:before,
.filter-check-box input[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #ebebeb;
  border-radius: 100%;
  background: #ffffff;
}

.filter-check-box input[type="radio"]:checked + label:after,
.filter-check-box input[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #f33535;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
}

.filter-check-box input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.filter-check-box input[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.filter-check-box input[type="radio"] + label:hover {
  color: #f33535;
}

/* check-box style CSS End */
#amount {
  border: 0;
  color: #1d1d1d;
  font-size: 14px;
  margin: 0 0 20px;
}

.check-box-inner .ui-widget-content {
  border: 1px solid #f33535;
}

.check-box-inner .ui-slider-horizontal {
  height: 5px;
}

.check-box-inner .ui-widget-header {
  background: #f33535;
}

.check-box-inner .ui-slider-handle {
  top: 50%;
  width: 15px;
  height: 15px;
  border: none;
  background: #ffffff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  outline: 0;
  cursor: pointer;
  margin-top: -7.5px;
  border-radius: 100%;
}

/*************************************
    check-box style CSS  END
*************************************/
/************************************ 
[ 16. static-media CSS ]
*************************************/
/******************************** 
     static media Css
  ********************************/
.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.static-media-wrap {
  padding: 45px 30px;
}

.static-media2 .title {
  font-family: "Gilroy-Bold ☞";
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .static-media2 .title {
    font-size: 15px;
  }
}

.static-media2 .text {
  line-height: 20px;
  font-size: 14px;
}

/******************************** 
     static media Css End
  ********************************/
/************************************ 
[ 17. media CSS ]
*************************************/
/*************************************
 media CSS 
*************************************/
@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .static-media.mr-50 {
    margin-right: 25px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .static-media.mr-50 {
    margin-right: 30px;
  }
}

.phone {
  line-height: 1;
}

.phone a {
  font-weight: 700;
  font-family: "Gilroy-Bold ☞";
  font-size: 16px;
}

.phone a:hover {
  color: #f33535 !important;
}

.phone span {
  font-family: "Gilroy-Regular ☞";
  line-height: 1;
  font-size: 14px;
}

.phone strong {
  line-height: 1;
}

.phone .email {
  line-height: 2rem;
}

/*************************************
 media CSS  ENd
*************************************/
/************************************ 
[ 18. pagination CSS ]
*************************************/
/******************************** 
   pagination
 ********************************/
.page-item {
  margin: 0 2px;
}

.page-item .page-link {
  font-family: "Gilroy-Regular ☞";
  display: block;
  padding: 0;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-weight: 400;
  color: #1d1d1d !important;
  background: #f6f6f6 !important;
  font-size: 18px;
  text-align: center;
  border: 0 !important;
  border-radius: 50% !important;
}

.page-item .page-link i {
  font-size: 13px;
  position: relative;
  top: -2px;
}

.page-item.active .page-link,
.page-link:hover {
  color: #ffffff !important;
  background: #f33535 !important;
}

/******************************** 
   pagination END
 ********************************/
/************************************ 
[ 19. modal CSS ]
*************************************/
/*************************************
 common modal CSS 
*************************************/
/* modal style1 */
.modal.style1 .modal-dialog {
  max-width: 1020px;
}

.modal.style1 .modal-header {
  border: none;
  padding: 6px 0.625rem;
  height: 30px;
}

.modal.style1 .modal-header .close {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  position: absolute;
  top: 25px;
  right: 30px;
  padding: 0;
}

.modal.style1 .modal-body {
  padding: 30px 30px 90px;
}

.modal.style1 .product-head .title {
  font-weight: 400;
  text-transform: capitalize;
  color: #707070;
  font-size: 24px;
  font-family: "Gilroy-Regular ☞";
  margin: 0 0 20px 0;
}

.modal.style1 .product-head .sub-title {
  font-size: 14px;
  color: #1d1d1d;
  margin: 0 0 10px 0;
}

.modal.style1 .product-head .star-content .star-on {
  color: #fdd835;
  font-size: 12px;
}

.modal.style1 .product-head .star-content .de-selected {
  color: #e0e0e0;
}

.modal.style1 .product-body {
  color: #777;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.modal.style1 .new-price {
  text-transform: capitalize;
  font-weight: 700;
  line-height: 30px;
  color: #1d1d1d;
  font-size: 26px;
  font-family: "Gilroy-Bold ☞";
  margin-bottom: 30px;
}

@media (max-width: 576px) {
  .modal.style1 .product-head .title {
    margin: 0 0 2rem 0;
    font-size: 18px;
    line-height: 1.4;
  }
}

.check-box .title {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #1d1d1d;
  line-height: 24px;
  font-family: "Gilroy-Bold ☞";
}

.pro-social-links ul li {
  margin-right: 15px;
}

.pro-social-links ul li.share {
  line-height: 30px;
  font-weight: 700;
  color: #1d1d1d;
  font-size: 14px;
  font-family: "Gilroy-Bold ☞";
}

.pro-social-links ul li a {
  display: block;
  width: 20px;
  text-align: center;
  color: #9d9d9d;
  font-size: 18px;
  line-height: 30px;
}

.theme1 .pro-social-links ul li a:hover {
  color: #f33535;
}

.theme2 .pro-social-links ul li a:hover {
  color: #ff7519;
}

.addto-whish-list a {
  display: inline-block;
  line-height: 30px;
  padding: 0;
  border: none;
  color: #666;
  font-size: 14px;
  margin-right: 15px;
}

.theme1 .addto-whish-list a:hover {
  color: #f33535;
}

.theme2 .addto-whish-list a:hover {
  color: #ff7519;
}

.theme3 .addto-whish-list a:hover {
  color: #10a341;
}

.product-discount {
  margin: 15px 0px 0px;
}

.regular-price {
  font-weight: 700;
  line-height: 30px;
  color: #1d1d1d;
  font-size: 26px;
  font-family: "Gilroy-Bold ☞";
}

/* modal style2 */
.modal.style2 .modal-dialog {
  max-width: 300px;
}

.modal.style2 .modal-header {
  border: 0;
  padding: 0;
}

.modal.style2 .modal-header .close {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  position: absolute;
  top: 25px;
  right: 30px;
  padding: 0;
  z-index: 999;
}

.modal.style2 .title {
  padding: 45px 25px;
  text-align: center;
  font-size: 14px;
  color: #707070;
}

.modal.style2 .title i {
  margin-right: 15px;
}

/* modal style3 */
.modal.style3 .modal-dialog {
  max-width: 1140px;
}

.modal.style3 .modal-header {
  padding: 15px;
  border-bottom: 1px solid #f5f5f5;
}

.modal.style3 .modal-header .close {
  opacity: 1;
  position: absolute;
  top: 25px;
  right: 30px;
  padding: 0;
  z-index: 999;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 #ffffff;
  font-family: "Gilroy-Bold ☞";
}

.modal.style3 .modal-header .close:hover {
  opacity: 1 !important;
  color: #253237 !important;
  color: #ffffff !important;
}

.modal.style3 .modal-title {
  text-align: center;
  padding-right: 20px;
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 24px;
  line-height: 1;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
}

.modal.style3 .modal-title span {
  font-size: 15px;
  margin-right: 15px;
}

.modal.style3 .modal-body {
  padding: 3.125rem 1.875rem;
}

.modal.style3 .product-name {
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
  color: #1d1d1d;
}

.modal.style3 .price {
  line-height: 1.4;
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 700;
  font-family: "Gilroy-Bold ☞";
}

.modal.style3 .color {
  line-height: 2.5;
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 700;
  font-family: "Gilroy-Bold ☞";
}

.modal.style3 .quantity {
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 700;
  font-family: "Gilroy-Bold ☞";
}

.modal-cart-content {
  padding-left: 2.5rem;
}

.modal-cart-content strong {
  font-weight: 700;
  font-family: "Gilroy-Bold ☞";
  color: #1d1d1d;
  font-size: 14px;
}

.modal-cart-content p {
  color: #1d1d1d;
  font-size: 14px;
}

@media (max-width: 767px) {
  .modal-cart-content {
    padding-left: 0px;
  }
}

.divide-right {
  border-right: 1px solid #ebebeb;
}

.dmc {
  font-size: 14px !important;
  font-weight: 400;
  font-family: "Gilroy-Regular ☞";
}

@media (max-width: 767px) {
  .divide-right {
    border-right: 1px solid transparent;
  }
}

@media (max-width: 991px) {
  .modal.style3 img {
    display: block;
    max-width: 180px;
    margin: 0 auto 1.5rem;
  }
}

.cart-content-btn i {
  font-size: 15px;
  margin-right: 15px;
  vertical-align: middle;
}

/* modal 4 style */
.style4 .modal-dialog {
  max-width: 600px;
}

.style4 .modal-title {
  font-weight: 400;
  font-family: "Gilroy-Regular ☞";
  padding: 10px;
  font-size: 13px;
  text-transform: uppercase;
  background: #1d1d1d !important;
}

.style4 .modal-header {
  padding: 30px 30px 0px;
  margin-bottom: 10px;
  border: 0px;
}

.style4 .modal-body {
  padding: 15px 30px 30px;
}

.style4 .modal-form > .title {
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  color: #1d1d1d;
  line-height: 24px;
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.style4 .modal-form label {
  margin: 12px 0 4px 0;
  text-align: right;
  display: block;
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  color: #1d1d1d;
  font-size: 14px;
}

.style4 .modal-form label.title {
  border-top: 1px solid #ebebeb;
  padding-top: 15px;
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  color: #1d1d1d;
  font-size: 14px;
}

.style4 .modal-form .form-control {
  padding: 0 5px;
  min-height: 28px;
  height: 28px;
  border: 1px solid #ebebeb;
  border-radius: 0;
  box-shadow: none;
}

.style4 .modal-form .form-control.textarea {
  height: 80px;
}

.style4 .modal-thumb img {
  border: 1px solid #ebebeb;
}

.style4 .modal-thumb .title {
  padding: 10px 0 5px;
  font-size: 13px;
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  color: #1d1d1d;
  line-height: 18px;
}

.style4 .star-content span {
  color: #fdd835;
  font-size: 18px;
}

.style4 .star-content span.quality {
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  color: #1d1d1d;
  font-size: 14px;
}

.style4 .required {
  font-size: 12px;
  color: #1d1d1d;
  margin-bottom: 10px;
}

.or {
  font-size: 12px;
  color: #1d1d1d;
}

/*************************************
 common modal CSS  ENd
*************************************/
/*------------------*
# Main Content ENd
*------------------*/
/************************************ 

[ 09.1 single-product CSS ]

*************************************/
/************************
   single-product start
 ************************/
.single-product-info .product-body .product-price .del {
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  line-height: 20px;
  color: #9b9b9b;
  margin-right: 10px;
  font-size: 22px;
}

.single-product-info .product-body .product-price .onsale {
  font-weight: 700;
  line-height: 30px;
  color: #1d1d1d;
  font-size: 26px;
}

.single-product-info .product-body .badge {
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  padding: 0 10px 0 10px;
}

.single-product-head .title {
  font-family: "Gilroy-Regular ☞";
  line-height: 1;
  font-weight: 400;
  color: #707070;
  font-size: 24px;
  text-transform: capitalize;
}

.single-product-head .star-content .star-on {
  margin: 0 1px 0 0;
  color: #fdd835;
  font-weight: 400;
  line-height: 1;
  font-size: 18px;
}

.single-product-head .star-content a {
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
}

.single-product-head .star-content a span {
  margin-right: 6px;
}

.theme3 .star-content a:hover {
  color: #10a341;
}

.product-tab-menu.single-product .nav-item .nav-link {
  text-transform: capitalize;
  color: #707070;
  line-height: 1;
  padding: 0 0 15px 0;
  margin: 0 30px;
  font-size: 26px;
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  border: 0px;
  border-radius: 0px;
  border-bottom: 2px solid transparent;
}

.product-tab-menu.single-product .nav-item .nav-link:hover {
  color: #1d1d1d;
  border-color: #10a341;
}

.product-tab-menu.single-product .nav-item .nav-link.active {
  color: #1d1d1d;
  border-color: #10a341;
}

.single-product-desc {
  background: #ffffff;
  padding: 30px;
  border: 1px solid #ebebeb;
}

.single-product-desc ul {
  font-size: 15px;
  line-height: 24px;
  text-align: left;
}

.studio-thumb img {
  border: 1px solid #ebebeb;
}

.studio-thumb h3 {
  font-family: "Gilroy-Medium ☞";
  text-transform: capitalize;
  font-weight: 500;
  color: #1d1d1d;
  line-height: 24px;
  font-size: 0.9375rem;
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
}

.studio-thumb h6 {
  font-family: "Gilroy-Bold ☞";
  font-size: 1rem;
  color: #1d1d1d;
  font-weight: 700;
}

.studio-thumb h6 small {
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  font-size: 14px;
  color: #707070;
}

.product-features ul {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.product-features ul li {
  flex: 1 0 40%;
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  background: #f1f1f1;
  padding: 0.625rem;
  margin-right: 0.625rem;
  min-height: 2.5rem;
  word-break: normal;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

.grade-content .grade {
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px;
  color: #777;
  margin-right: 10px;
}

.grade-content .star-on {
  color: #fdd835;
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  font-size: 18px;
}

.grade-content .title {
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px;
  color: #777;
  margin-top: 20px;
  margin-bottom: 10px;
}

.grade-content .sub-title {
  color: #1d1d1d;
  margin-bottom: 10px;
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  line-height: 18px;
  font-size: 14px;
  padding-top: 9px;
  margin-bottom: 10px;
}

.grade-content p {
  line-height: 18px;
  font-size: 14px;
  color: #9d9d9d;
}

.group-img img {
  max-width: 80px;
}

.product-grouped .title {
  margin-bottom: 0.8rem;
  color: #1d1d1d;
  font-size: 15px;
  text-transform: capitalize;
}

.product-grouped span {
  font-size: 14px;
  color: #707070;
  line-height: 1;
}

/************************
   single-product End
 ************************/
/**************************** 
[ 21. footer CSS ]
*****************************/
/*------------------*
# Footer
*------------------*/
.nletter-form .form-control {
  height: 47px;
  background: #ffffff;
  color: #7e7e7e;
  padding: 10px 120px 10px 10px;
  width: 100%;
  font-size: 14px;
  border: 1px solid #ffffff;
  border-radius: 30px;
}

.nletter-form .form-control:focus {
  box-shadow: none;
}

.social-network li {
  margin: 0 10px 0 0;
}

.social-network li a {
  display: block;
  color: #1d1d1d;
  background: #ffffff;
  line-height: 40px;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
}

.theme1 .social-network li a:hover {
  color: #ffffff;
  background: #f33535;
}

.theme2 .social-network li a:hover {
  color: #ffffff;
  background: #ff7519;
}

.theme3 .social-network li a:hover {
  color: #ffffff;
  background: #10a341;
}

footer .container {
  z-index: 2;
  position: relative;
}

footer::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(35% + 33px);
  height: 100%;
  content: "";
  background: #f6f6f6;
  z-index: 1;
}

@media screen and (min-width: 1700px) and (max-width: 1800px) {
  footer::after {
    width: calc(30% + 88px);
  }
}

@media screen and (min-width: 1600px) and (max-width: 1699px) {
  footer::after {
    width: calc(30% + 67px);
  }
}

@media screen and (min-width: 1500px) and (max-width: 1599px) {
  footer::after {
    width: calc(30% + 50px);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1499px) {
  footer::after {
    width: calc(30% + 40px);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  footer::after {
    width: calc(30% + 20px);
  }
}

@media screen and (max-width: 991px) {
  footer::after {
    background: #efefef;
  }
}

.address {
  margin: 0;
}

.address-widget .title {
  font-size: 22px;
  font-weight: 500;
}

.address-widget .text {
  line-height: 1;
  color: #707070;
  font-size: 13px;
}

.theme1 .address-widget .title a:hover {
  color: #f33535;
}

.theme2 .address-widget .title a:hover {
  color: #ff7519;
}

.theme3 .address-widget .title a:hover {
  color: #10a341;
}

/* footer-menu */
.mx-w-400 {
  max-width: 400px;
}

.help-text {
  font-size: 16px;
  margin-bottom: 5px;
}

.footer-widget .section-title {
  position: relative;
}

.footer-widget .section-title::after {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 60px;
  height: 2px;
  content: "";
}

.footer-widget .section-title .title {
  font-size: 15px;
  font-weight: 700;
  font-family: "Gilroy-Bold ☞";
}

.theme1 .footer-widget .section-title::after {
  background: #f33535;
}

.theme2 .footer-widget .section-title::after {
  background: #ff7519;
}

.theme3 .footer-widget .section-title::after {
  background: #10a341;
}

.footer-menu li {
  margin-bottom: 5px;
}

.footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-menu li a {
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  color: #666;
}

.footer-menu li a:hover {
  padding-left: 10px;
}

.theme1 .footer-menu li a:hover {
  color: #f33535;
}

.theme2 .footer-menu li a:hover {
  color: #ff7519;
}

.theme3 .footer-menu li a:hover {
  color: #10a341;
}

/* coppy-right */
.coppy-right a {
  color: #253237;
}

.theme1 .coppy-right a:hover {
  color: #f33535;
  text-decoration: underline;
}

.theme2 .coppy-right a:hover {
  color: #ff7519;
  text-decoration: underline;
}

.theme3 .coppy-right a:hover {
  color: #10a341;
  text-decoration: underline;
}

/******************************** 
    footer section End
  ********************************/
/**************************** 
[ 22. scroll-up CSS ]
*****************************/
/******************************** 
    scrollUp style
 ********************************/
#scrollUp.theme4 {
  background: #10a341;
  width: 40px;
  height: 40px;
  bottom: 50px;
  right: 50px;
  border-radius: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 22px;
  line-height: 40px;
  font-weight: 700;
}

#scrollUp.theme4:hover {
  background: #253237;
  color: #ffffff;
  box-shadow: 0 0 20px #ffffff;
}

.theme-default {
  background: #f33535;
  width: 40px;
  height: 40px;
  bottom: 50px;
  right: 50px;
  border-radius: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 22px;
  line-height: 40px;
  font-weight: 700;
}

.theme-default.theme2 {
  background: #ff7519;
}

.theme-default:focus {
  color: #ffffff;
}

.theme-default:hover {
  background: #253237;
  color: #ffffff;
  box-shadow: 0 0 20px #ffffff;
}

/******************************** 
    scrollUp style End
 ********************************/
/**************************** 
[ 23. pages ]
*****************************/
/**************************** 
[ 23.1 blogPages ]
*****************************/
/*------------------*
# Blog Pages
*------------------*/
/* 10. Blog Section CSS */
.blog-title {
  margin-bottom: 30px;
}

.blog-title .title {
  color: #1d1d1d;
  font-size: 34px;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  line-height: 1;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  display: block;
  border-bottom: 1px solid #ebebeb;
  text-transform: capitalize;
}

.blog-title .title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 60px;
  height: 2px;
  background: #f33535;
}

/* blog left sidebar */
.sidebar-widget .post-title {
  font-size: 20px;
  line-height: 1;
  color: #1d1d1d;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  position: relative;
  display: block;
  border-bottom: 1px solid #ebebeb;
  text-transform: capitalize;
}

.sidebar-widget .post-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 60px;
  height: 2px;
  background: #f33535;
}

.blog-search-form .form-control {
  height: 46px;
  border: 2px solid #f33535;
  font-size: 14px;
}

.blog-media-list .sub-title {
  font-size: 14px;
  color: #1d1d1d;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
}

.post-thumb img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  object-position: center;
}

.object-fit-none {
  object-fit: none;
}

.font-style-normal {
  font-style: normal !important;
}

.comment-section {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #ebebeb;
}

.comment-section .title {
  text-transform: capitalize;
  color: #1d1d1d;
  line-height: 24px;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.comment-section .sub-title {
  text-transform: capitalize;
  color: #1d1d1d;
  line-height: 24px;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.comment-section span {
  display: block;
  text-transform: capitalize;
}

.comment-section p {
  margin: 30px 0;
  font-size: 14px;
}

.reply {
  color: #1d1d1d;
}

.reply:hover {
  color: #f33535;
}

.avatar {
  margin: 20px 0;
}

/******************************** 
    Blog Section End
 ********************************/
/**************************** 
[ 23.2 shopPages ]
*****************************/
/*------------------*
# Shop Pages
*------------------*/
.tab-content .tab-pane.active .grid-view .product-card {
  animation: zoomIn 0.5s ease;
  animation-duration: 1s;
}

.tab-content .tab-pane.active .grid-view-list .product-card {
  animation: fadeInRight 0.5s ease;
  animation-duration: 1s;
}

.grid-nav-wraper {
  padding: 10px 30px;
}

@media screen and (max-width: 991px) {
  .grid-nav-wraper {
    padding: 10px 15px;
  }
}

.shop-grid-nav .nav .nav-item {
  margin-right: 15px;
  line-height: 1;
}

.total-products {
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  margin-left: 50px;
  line-height: 20px;
  font-size: 14px;
  padding: 5px;
  color: #1d1d1d;
}

@media screen and (max-width: 991px) {
  .total-products {
    margin-left: 20px;
  }
}

.shop-grid-nav .nav .nav-link {
  color: #1d1d1d;
  padding: 0;
  background: transparent;
  font-size: 18px;
  line-height: 30px;
}

.shop-grid-nav .nav .nav-link.active,
.shop-grid-nav .nav .nav-link:hover {
  color: #f33535;
}

.shop-grid-button button {
  width: 100%;
  border: 1px solid #ebebeb;
  background: #ffffff;
  padding: 2px 15px;
  line-height: 24px;
  font-size: 14px;
  color: #1d1d1d;
  text-align: left;
  box-shadow: none;
  border-radius: 30px;
}

.shop-grid-button button span {
  font-size: 20px;
}

.shop-grid-menu {
  background: #ffffff;
  width: calc(100% - 145px);
  border: 1px solid #ebebeb;
}

.shop-grid-menu .dropdown-item {
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
  padding: 3px 15px;
  color: #1d1d1d;
}

.shop-grid-menu .dropdown-item:hover {
  background: #f33535;
  color: #ffffff;
}

.sort-by {
  min-width: 70px;
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  padding: 5px;
  color: #1d1d1d;
  margin-left: 50px;
}

@media screen and (max-width: 991px) {
  .sort-by {
    margin-left: 0px;
  }
}

.product-list-des {
  padding: 30px 0;
}

.availability-list p span {
  color: #10a341;
}

/*------------------*
# Shop Pages End
*------------------*/
/**************************** 
[ 23.3 about-us ]
*****************************/
/*------------------*
# About Us Page
*------------------*/
.about-content .title {
  font-size: 34px;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  color: #1d1d1d;
  text-transform: capitalize;
}

.about-info .title {
  font-size: 34px;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  color: #1d1d1d;
}

/* *******************
   about us page End
 **********************/
/**************************** 
[ 23.4 whishlist ]
*****************************/
/*------------------*
# Whishlist Page
*------------------*/
@media (max-width: 991px) {
  .whish-list-section .table {
    min-width: 1000px;
  }
}

.whish-list-section .title {
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  color: #1d1d1d;
  font-size: 20px;
  border-bottom: 1px solid #ebebeb;
}

.whish-list-section .table .thead-light th {
  background-color: #f33535;
  border-color: transparent;
  text-transform: capitalize;
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  color: #ffffff;
  font-size: 16px;
}

.whish-list-section .table td,
.whish-list-section .table th {
  vertical-align: middle;
}

.whish-list-section img {
  max-width: 200px;
}

.whish-title {
  color: #1d1d1d;
  font-size: 15px;
}

.whish-list-price {
  color: #1d1d1d;
  font-size: 15px;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f1f1f1 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=) no-repeat scroll right 0.5rem center/1.25rem 1.25rem;
}

/* ***************
  whishlist End
 ******************/
/**************************** 
[ 23.5 contact-us ]
*****************************/
/*------------------*
# Contact Us Page
*------------------*/
/* ***************
 contact-form
******************/
.form-control:focus {
  box-shadow: none;
}

.contact-form .title {
  text-transform: capitalize;
  color: #1d1d1d;
  line-height: 24px;
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.col-form-label {
  font-size: 14px;
  color: #1d1d1d;
  padding-top: 0.625rem;
  text-transform: capitalize;
}

.contact-form .form-control {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  font-size: 0.6875rem;
  color: #707070;
  padding: 0.8rem 1.6rem;
  height: 38px;
  line-height: 1.25;
  border-radius: 0px;
}

.contact-form textarea.form-control {
  min-height: 300px;
}

@media screen and (max-width: 767px) {
  .contact-form textarea.form-control {
    min-height: 150px;
  }
}

h3.contact-page-title {
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  font-size: 30px;
  line-height: 32px;
  margin-bottom: 25px;
  color: #1d1d1d;
}

@media only screen and (max-width: 575px) {
  h3.contact-page-title {
    font-size: 25px;
  }
}

.single-contact-block {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (max-width: 575px) {
  .single-contact-block {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

.single-contact-block:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.single-contact-block h4 {
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #1d1d1d;
  margin: 15px 0px;
}

@media only screen and (max-width: 575px) {
  .single-contact-block h4 {
    font-size: 20px;
  }
}

.single-contact-block h4 img {
  margin: 5px 20px 20px 0;
}

.single-contact-block p {
  font-size: 15px;
  line-height: 29px;
  margin-bottom: 0;
  color: #707070;
}

span.required {
  color: #f33535;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form label {
  font-size: 14px;
  color: #1d1d1d;
  padding: 0.8rem 0rem;
  text-transform: capitalize;
}

.contact-form input {
  line-height: 40px;
  width: 100%;
  height: 40px;
  padding-left: 20px;
  border: 1px solid #ebebeb;
  outline: 0;
  background-color: #ffffff;
}

.contact-form textarea {
  line-height: 40px;
  width: 100%;
  height: 155px;
  padding-left: 20px;
  border: 1px solid #ebebeb;
  outline: 0;
  background-color: #ffffff;
}

.contact-page-side-content {
  padding-right: 30px;
  height: 100%;
}

/* login page */
.log-in-form {
  border: 1px solid #ebebeb;
  font-size: 13px;
  color: #707070;
  padding: 30px 15px 15px;
}

.log-in-form .form-control {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  font-size: 14px;
  color: #707070;
  padding: 0.8rem 1.6rem;
  height: 38px;
  line-height: 1.25;
  border-radius: 0px;
}

.show-password {
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  color: #ffffff;
  border: 0;
  text-transform: uppercase;
  font-size: 0.6875rem;
  padding: 0.67rem 1rem !important;
  border-radius: 0px;
}

.login-form-links a {
  display: inline-block;
}

.for-get {
  font-size: 14px;
  padding-bottom: 15px;
}

.border-top {
  border-top: 1px solid #ebebeb !important;
}

.no-account {
  display: inline-block;
  font-size: 14px;
  margin-top: 15px;
}

/* ***************
 contact-form End
******************/
/*------------------*
# Contact Us End
*------------------*/
/**************************** 
[ 23.6 account ]
*****************************/
/*------------------*
# Account Page
*------------------*/
.my-account .title {
  color: #1d1d1d;
  border-bottom: 1px solid #ebebeb;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 34px;
}

.register .title {
  color: #1d1d1d;
  border-bottom: 1px solid #ebebeb;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 34px;
}

.myaccount-tab-menu {
  flex-direction: column;
  background-color: #ffffff;
}

.myaccount-tab-menu a {
  border: 1px solid #ebebeb;
  border-bottom: 0;
  color: #1d1d1d;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  display: block;
  padding: 15px 15px 13px;
  line-height: 30px;
  font-size: 15px;
  text-transform: uppercase;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #ebebeb;
}

.myaccount-tab-menu a:hover,
.myaccount-tab-menu a.active {
  background-color: #f33535;
  color: #ffffff;
}

.myaccount-tab-menu a i {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

.myaccount-content {
  background-color: #ffffff;
  font-size: 14px;
  border: 1px solid #ebebeb;
  padding: 30px;
}

@media only screen and (max-width: 575px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}

.myaccount-content h3 {
  color: #1d1d1d;
  border-bottom: 1px solid #ebebeb;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 25px;
}

.myaccount-content .welcome a {
  color: #1d1d1d;
}

.myaccount-content .welcome a:hover {
  color: #f33535;
}

.myaccount-content .welcome strong {
  font-weight: 600;
}

.myaccount-content a.edit-address-btn {
  border-color: #333;
}

.myaccount-content a.edit-address-btn i {
  padding-right: 5px;
}

.myaccount-content a.edit-address-btn:hover {
  color: #f33535;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 15px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 20px 10px;
  vertical-align: middle;
}

.myaccount-table table td a:hover,
.myaccount-table .table td a:hover {
  color: #ffffff;
}

.saved-message {
  font-weight: 600;
  font-size: 13px;
  padding: 20px;
}

.account-details-form h4 {
  text-transform: capitalize;
  margin: 0;
  color: #1d1d1d;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  font-size: 18px;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.account-details-form input[type="text"],
.account-details-form input[type="email"],
.account-details-form input[type="url"],
.account-details-form input[type="password"],
.account-details-form input[type="search"],
.account-details-form input[type="number"],
.account-details-form input[type="tel"] {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  font-size: 14px;
  color: #707070;
  padding: 0.8rem 1.6rem;
  height: 38px;
  line-height: 1.25;
  border-radius: 0px;
}

/* ***************
  my-account End
 ******************/
/**************************** 
[ 23.7 check-out ]

*****************************/
/*------------------*
# Check out Page
*------------------*/
.form-group {
  margin-bottom: 1rem;
  align-items: center;
}

.checkout-inner {
  border: 1px solid #ebebeb;
}

.checkout-header .title {
  font-size: 15px;
  text-transform: capitalize;
  color: #1d1d1d;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
}

.checkout-address .title {
  font-size: 15px;
  text-transform: capitalize;
  color: #1d1d1d;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
}

.checkout-address .title span {
  padding: 0.625rem;
}

.checkout-address p {
  padding: 0.9375rem;
  font-size: 14px;
  color: #7e7e7e;
}

.check-out-content {
  padding: 0.9375rem;
}

.check-out-content form {
  padding: 0 2.313rem;
}

.check-out-content .form-group label {
  font-size: 14px;
  color: #1d1d1d;
  padding-top: 0.625rem;
}

.check-out-content .form-group label.checkout {
  font-size: 14px;
  color: #1d1d1d;
}

.check-out-content .form-control {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  color: #1d1d1d;
  font-size: 14px;
  padding: 0.5rem 1rem;
  line-height: 1.25;
  border-radius: 0px;
}

.optional {
  color: #7e7e7e;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-group span {
  font-size: 14px;
  color: #7e7e7e;
}

.checkout-step {
  padding: 2rem 2rem;
  border-top: 1px solid #ebebeb;
}

.checkout-step .title {
  font-size: 15px;
  text-transform: capitalize;
  color: #1d1d1d;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
}

/* list-group-item */
.delivery-info {
  border-top: 1px solid #ebebeb;
  padding-top: 30px;
}

.cart-summary .list-group-item {
  padding: 1.25rem;
}

ul.items li {
  text-transform: capitalize;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  font-size: 14px;
  color: #1d1d1d;
}

ul.amount li {
  color: #1d1d1d;
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.delivery-info ul li {
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #1d1d1d;
  line-height: 24px;
  margin-bottom: 25px;
}

.delivery-info ul li img {
  margin-right: 10px;
}

#accordion .card {
  margin-bottom: 1rem;
  border-radius: 0px;
}

#accordion .card .card-header {
  border: 0;
  padding: 0.9375rem;
  background-color: #ffffff;
  border-radius: 0px;
}

#accordion .card .card-header .btn-link {
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  text-transform: capitalize;
  color: #1d1d1d;
  line-height: 24px;
  font-size: 1.125rem;
  padding: 0px;
}

.custom-radio input[type="radio"]:checked,
.custom-radio input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.custom-radio input[type="radio"]:checked + label,
.custom-radio input[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  line-height: 15px;
  display: inline-block;
  color: #666;
  margin-bottom: 0;
  margin-right: 15px;
}

.custom-radio input[type="radio"]:checked + label:before,
.custom-radio input[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 15px;
  height: 15px;
  border: 2px solid #ebebeb;
  background: #ffffff;
  border-radius: 50%;
}

.custom-radio input[type="radio"]:checked + label:after,
.custom-radio input[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 7px;
  height: 7px;
  background: #f33535;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: all 0.3s;
  border-radius: 50%;
}

.custom-radio-modify .custom-radio input[type="radio"]:checked + label:before,
.custom-radio-modify .custom-radio input[type="radio"]:not(:checked) + label:before {
  left: 15px;
  top: -12px;
}

.custom-radio-modify .custom-radio input[type="radio"]:checked + label:after,
.custom-radio-modify .custom-radio input[type="radio"]:not(:checked) + label:after {
  top: -8px;
  left: 19px;
}

.custom-radio input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.custom-radio input[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.order-asguest {
  color: #1d1d1d;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
}

.order-asguest a {
  color: #1d1d1d;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
}

.order-asguest .separator {
  color: #bfbfbf;
  margin: 0 8px;
  line-height: 1;
  font-size: 1.3rem;
  width: 1px;
  height: 12px;
  background: #1d1d1d;
  position: relative;
  top: 2px;
}

.order-asguest .gray {
  color: #7e7e7e;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
}

.personal-information .form-group .col-form-label {
  font-size: 14px;
  color: #1d1d1d;
  padding-top: 0.625rem;
  text-transform: capitalize;
}

.personal-information .form-group .form-control {
  background-color: #ffffff;
  color: #1d1d1d;
  border: 1px solid #ebebeb;
  font-size: 14px;
  padding: 0.5rem 1rem;
  border-radius: 0px !important;
}

.delivery-option {
  background: #f6f6f6;
  padding: 1.5rem 0;
  margin-bottom: 1.5rem;
}

.carrier-name {
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  text-transform: capitalize;
  color: #1d1d1d;
  line-height: 24px;
  font-size: 14px;
}

.carrier-delay {
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  color: #1d1d1d;
  line-height: 24px;
  font-size: 14px;
}

.carrier-price {
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  color: #1d1d1d;
  line-height: 24px;
  font-size: 14px;
}

/* ***************
  checkout End
 ******************/
/**************************** 
[ 23.8 compare ]

*****************************/
/*------------------*
# Compare Page
*------------------*/
@media (max-width: 991px) {
  .compare-section .table {
    min-width: 800px;
  }
}

.compare-section img {
  max-width: 150px;
}

.compare-section .title {
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  color: #1d1d1d;
  font-size: 20px;
  border-bottom: 1px solid #ebebeb;
}

.compare-section .sub-title {
  font-family: "Gilroy-Bold ☞";
  font-weight: 700;
  color: #1d1d1d;
  font-size: 15px;
  padding: 20px 0;
}

.compare-section .table .thead-light th {
  background-color: #ffffff;
  border-color: #ebebeb;
}

.compare-section .table td,
.compare-section .table th {
  vertical-align: middle;
  border: 1px solid #ebebeb;
  padding: 15px;
  font-size: 15px;
}

.compare-section .table th {
  text-transform: capitalize;
}

.compare-section .table td {
  text-transform: capitalize;
}

.compare-section .table td p {
  text-transform: initial;
}

/* ***************
  compare End
 ******************/
/**************************** 
[ 23.9 map ]

*****************************/
/*******************
   map page start
 ******************/
.map iframe {
  width: 100%;
  height: 450px;
  border: 0;
}

/* ***************
   map page End
 ******************/
